@import url(//db.onlinewebfonts.com/c/e16d93211abd23ce0cfc21ec1ae2f881?family=SBL+Hebrew);
@import url(//db.onlinewebfonts.com/c/4267d8aa8711bb8c72cbefb26066c9e0?family=Bookman+Old+Style);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face{
  font-family:'StamAshkenazCLM';
  src: url(/static/media/StamAshkenazCLM.7044ac30.woff) format('woff')
}

@font-face{
  font-family:'Hebrew-Paleo-Gezer';
  src: url(/static/media/Hebrew-Paleo-Gezer.0f6ee000.woff) format('woff')
}

@font-face{
  font-family:'Shlomo Stam';
  src: url(/static/media/ShlomoStam.c5a10bf0.woff) format('woff')
}

.App {
  text-align: center;
}

.App-logo {
  //animation: App-logo-spin infinite 1s linear;
  height: 96.89px;
}

.App-header {
  background-color: #d655ba69;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

a.siteMap:visited, a.siteMap:link {color: #4070FF !important;}

.maint-header-he {
  background-color: #FFFFFF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(20px + 2vmin);
  font-weight: bold;
  font-family: TimesNewRoman;
  color: blue;
}

.maint-header-en {
  font-size: calc(16px + 2vmin);
  font-style: italic;
}

.maint-logo {
  height: 30vmin;
}

.App-link {
  color: 61baff !important; /* #61dafb; */
}

.container, .container-fluid {
  margin-top: 20px;
} 

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
} 

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.navbar-header {
  background-color: #000000;
  color: #61baff;
  vertical-align: top;
  height: 100px;
  overflow: hidden;
  padding: 0;
}

#navbar-outer-table{
  background-color: black;
  background-color: #000000;
  color: #61baff;
}

/* standard copper = b87333, color of brown eitz chaim in logo = 966731     61baff;*/
.rightbar {
    border: 0px;
    padding: 14px 4px 4px 4px !important;
    background-color: #303030;
    color: #b87333;
    line-height: 20px;
    height: 860px;
    vertical-align: top  !important;
    text-align: right !important;
    direction: rtl !important;
}

.rightbar-header {
    border: 0px;
    padding: 0px 4px 1.2px 4px !important;
    background-color: #303030;
    color: #b87333;
    line-height: 1px;
    height: 1px;
    vertical-align: top  !important;
    text-align: center !important;
    font-weight: bold;
    font-size: 16px !important;
    direction: rtl !important;
}

.rightbar-content {
    border: 0px;
    background-color: #303030;
    color: #b87333;
    line-height: 20px;
    height: 20px;
    vertical-align: top  !important;
    text-align: right !important;
    font-weight: regular !important;
    font-family: Calibri !important;
    font-size: 16px !important;
    direction: rtl !important;
}

.leftbar-header {
/*
    border: 0px;
    background-color: #303030;
    color: #b87333;
    line-height: 20px;
    height: 860px;
    vertical-align: top  !important;
    text-align: right !important;
    direction: rtl !important;
*/
    border: 0px;
    padding: 0px 4px 1.2px 4px !important;
    background-color: #303030;
    color: #b87333;
    line-height: 10px;
    height: 1px;
    vertical-align: top  !important;
    text-align: center !important;
    font-weight: bold;
    font-size: 16px !important;
    direction: rtl !important;
}

.leftbar-content {
    border: 0px;
    background-color: #303030;
    color: #b87333;
    line-height: 20px;
    height: 20px;
    vertical-align: top  !important;
    text-align: right !important;
    font-weight: regular !important;
  font-family: Arial;

    font-size: 14px !important;
    direction: rtl !important;
}

.navbar-caption {
    padding: 6px 6px 0px 6px !important;
    background-color: #000000;
    color: #61baff;
  line-height: 20px;
  height: 20px;
  font-family: Calibri;
  font-size: 18px !important;
  vertical-align: middle  !important;
}

.header-large {
    background-color: #000000;
    color: #61baff;
  font-size: 36px !important;
  font-weight: bold;
  font-family: Times New Roman;
}

.navbar-component {
  line-height: 20px;
  height: 20px;
  font-family: Times New Roman;
  font-size: 16px;
}

.navbar-component2 {
  line-height: 15px;
  height: 15px;
  font-family: Times New Roman;
  font-size: 16px;
  direction: rtl !important;
}

.navbar-text {
    background-color: #000000;
    color: #61baff;
}

.navbar-logo {
    align: center;
}

.company-logo {
    align: left;
}

.hebrewInput {
    text-align: right;
    width: 1200px !important;
    font-size: 28px !important;
}

.bhsButton {
    font-size: 14px;
    /* font-weight: regular; */
}

.bhsButtonLarge {
    font-size: 20px;
    font-weight: bold;
}

.buttonToolbar {
    align: right !important;
}

.main-body {
    vertical-align: top !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.searchLine {
  line-height: 0px;
  height: 0px;
}

.bsd {
  font-weight: bold;
  font-size: 18px;
  font-style: normal;
  font-family: Times New Roman;
}

.caption {
    line-height: 10px;
    height: 10px;
    padding: 0px;
    font-size: 16px;
}

.placementCaption {
    line-height: 0px;
    height: 0px;
    padding: 0px;
    font-size: 16px;
}

.medCaption {
    line-height: 20px;
    height: 20px;
    font-size: 18px;
    font-weight: bold;
    color: #3060FF;
;
}

/*
.row-header {
    background-color: #707070;
    color: #FFFFFF;
    text-align: right;
    vertical-align: middle;
    line-height: 12px;
    height: 12px;
    padding: 0px;
}
*/



.row-thin {
/*
    line-height: 8px;
    height: 8px;
    padding: 0px;
        text-align: right;

    */
    text-align: right;
    font-size: 14px;
}

.row-thin-sm {
/*
    line-height: 8px;
    height: 8px;
    padding: 0px;
        text-align: right;

    */
    text-align: right;
    font-size: 12px;
}

.row-thin-small {
/*
    line-height: 8px;
    height: 8px;
    padding: 0px;
        text-align: right;

    */
    text-align: right;
    font-size: 12px;
    margin-top: 0px !important;
    padding-top: 0px !important;
    padding-right: 4px !important;
}

.sort-column {
    text-align: right;
    font-size: 12px;
    margin-top: 0px !important;
    padding-top: 0px !important;
    padding-right: 4px !important;
    background-color: #bfbfbf !important;
}

.outlier-row.sort-column{
    background-color: #a1b3ff !important;
}
.outlier-row.row-header-large, .outlier-row.row-header-center{
    background-color: #c9d4ff !important;
}

.row-thin-bold {
    text-align: right;
    font-size: 14px;
    font-weight: bold;
}

.row-thin-center {
    text-align: center !important;
    font-size: 14px;
}

.row-thin-left {
    text-align: left;
    font-size: 14px;
}

.gridBox {
    max-width: 600;
    max-height: 400;
    width: 600;
    height: 400;
}

.ag-header-cell {
    background-color: #707070;
    color: #FFFFFF;
    font-size: 16px;
}

/* the following needed for word-wrap in row headers */
.ag-header-cell-label .ag-header-cell-text {
    white-space: normal !important;
 }

.ag-header-cell-small {
    background-color: #707070;
    color: #FFFFFF;
    font-size: 12px;
    padding: 1px !important;
    margin: 1px !important;
}

.ag-header-cell-small-font {
    background-color: #707070;
    color: #FFFFFF;
    font-size: 12px;
}

.ag-header-cell-center {
    background-color: #707070;
    color: #FFFFFF;
    font-size: 16px;
    text-align: center;
}

.ag-header-cell-left {
    background-color: #707070;
    color: #FFFFFF;
    font-size: 16px;
    text-align: left !important;
}

.ag-cell {
    border-left: 1px solid lightgray !important;
}

.row-header {
    background-color: lightgray;
    color: #000000;
    font-weight: bold;
    font-size: 14px;
    text-align: right;
}

.row-header-small {
    background-color: lightgray;
    color: #000000;
    font-weight: bold;
    font-size: 11.5px;
    text-align: right;
}

.row-header-large {
    background-color: lightgray;
    color: #000000;
    font-weight: bold;
    font-size: 16px;
    text-align: right;
}

.row-header-super {
    background-color: lightgray;
    color: #000000;
    font-weight: bold;
    font-size: 18px;
    text-align: right;
}

.row-header-center {
    background-color: lightgray;
    color: #000000;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
}

.row-header-center-regular {
    background-color: lightgray;
    color: #000000;
    font-size: 11px;
    text-align: center;
}

.row-header-center-compact {
    background-color: lightgray;
    color: #000000;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    padding: 0px !important;
    margin: 0px !important;
}



.row-header-left {
    background-color: lightgray;
    color: #000000;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
}

.row-header-right {
    background-color: lightgray;
    color: #000000;
    font-weight: bold;
    font-size: 14px;
    text-align: right;
}

.nikkud-header-right {
    background-color: lightgray;
    color: #000000;
    font-weight: bold;
    font-size: 14px;
    text-align: right;
    height: 40px;
}


.grid-alternate-row {
    background: '#F0F0F0';
}

/* all props marked important to override reactstrap styles*/
.dropdownToggle {
        background-color: #000000 !important;
        color: #61baff !important;
        font-family: Calibri !important;
        font-weight: bold !important;
        font-size: 18px !important;
        direction: rtl !important;
    }

.dropdownToggle-small {
        background-color: #000000 !important;
        color: #61baff !important;
        font-family: Calibri !important;
        font-weight: bold !important;
        font-size: 14px !important;
        direction: rtl !important;
    }

.dropdownItem {
        background-color: white !important;
        color: #000000 !important;
        cursor: pointer;
        /*text-align: center !important;*/
        font-family: Calibri !important;
        font-weight: bold !important;
        font-size: 17px !important;
        /*direction: rtl !important;*/
        min-height: 40px !important;
        min-width: 200px !important;
        -webkit-transition: .25s;
        transition: .25s;

    }

.dropdownItem:hover {
        background-color: #4cacf5 !important;
    }

.dropdownItemFuture {
        background-color: #D8D8D8 !important;
        color: #707070 !important;
        text-align: center !important;
        font-family: Calibri !important;
        font-weight: bold !important;
        font-size: 17px !important;
        direction: rtl !important;
    }

.dropdownDivider {
        margin: 0px !important;
    }


.page-caption-italic {
    font-size: 17px;
    font-style: italic;
    font-family: Calibri;
    color: #61baff;
}

.invisible {
    visibility: hidden;
    position: absolute;
    top: -9999px;
}

.visible {
    visibility: visible;
    position: static;
    initialSize: 200px;
    minSize: 20px;
    line-height: 200px;
    height: 200px;
}

.toggleButton-large {
    width: 100px !important;
}

.toggleButton-medium {
    width: 62px !important;
    min-height: 24px !important;
}

.toggleButton-small {
    width: 4px !important;
    max-width: 4px !important;
    min-height: 24px !important;
}

.toggleButton-div {
    line-height: 8px !important;
    height: 8px !important;
    width: 160px !important;
}

.verticalLine {
  border-left: 2px solid black;
  display: inline;
  margin: 0 10px;
}

.closeIcon {
  margin: 10px;
  right: 0%;
}

.rg {
  width: 600px !important;
  height: 340px !important;
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
  background-color: white;
}

.tabs {
  font-size: 16px !important;
  font-weight: bold !important;
  min-height: 40px !important;
  height: 40px !important;
}

.MuiTabs-root-309 {
  min-height: 40px !important;
}

/*
.MuiTab-root-319 {
  min-height: 16px !important;
}
*/

#advanced-search-table [class*="MuiPrivateTabScrollButton"]{
    width: 40px;
}

#advanced-search-table [class*="MuiTabs-flexContainer"]{
  overflow: visible !important;
}

.tabs#inclusion-exclusion, .tabs#nikud-taam{
  width: 20% !important;
}

.tabs#distance, .tabs#placement, .tabs#parts-of-speech{
  width: 20% !important;
}

@media (min-width: 1910px) {
  /* CSS that should be displayed if width is equal to or less than 800px goes here */
    #advanced-search-table [class*="MuiPrivateTabScrollButton"]{
        width: 0px;
        color: white;
        z-index: -1;
    }

    #advanced-search-table [class*="MuiTabs-flexContainer"]{
        width: 1180px !important;
        overflow: auto;
    }
    /*757*/

/*
    .tabs#inclusion-exclusion{
      min-width: 28% !important;
    }
*/
    /*160*/

/*
    .tabs#distance{
      min-width: 22% !important;
    }
*/
    /*85*/

/*
    .tabs#nikud-taam{
      min-width: 28% !important;
    }
*/
    /*230*/

/*
    .tabs#placement{
      min-width: 22% !important;
    }
*/
    /*90*/
}

.termBox1 {
  align: right !important;
  width: 295px !important;
  background-color: #f7f7f7 !important;
}
.termBox1a {
  font-size: 19px !important;
  direction: rtl !important;
  width: 180px !important;
  vertical-align: middle !important;
  line-height: 30px !important;
  height: 30px !important;
}
.termBox1b {
  font-size: 12px !important;
  font-weight: bold !important;
  text-align: center !important;
  width: 85px !important;
  background-color: lightgray;
  line-height: 36px !important;
  height: 36px !important;
}

.termBox2 {
  align: right !important;
  width: 295px !important;
}
.termBox2a {
  font-size: 12px !important;
  direction: rtl !important;
  width: 180px !important;
  line-height: 24px !important;
  height: 24px !important;
}
.termBox2b {
  font-size: 12px !important;
  font-weight: bold !important;
  text-align: center !important;
  width: 85px !important;
  background-color: lightgray;
  line-height: 24px !important;
  height: 24px !important;
  marginLeft: '15px'
}

.termBox-div {
    line-height: 4px !important;
    height: 4px !important;
    width: 160px !important;
}

.advSearchBox {
  align: right !important;
  width: 60px !important;
}

.advSearchValue {
  font-size: 12px !important;
  direction: rtl !important;
  width: 10px !important;
  line-height: 24px !important;
  height: 24px !important;
}
.advSearchCaption {
  font-size: 12px !important;
  font-weight: bold !important;
  text-align: center !important;
  width: 35px !important;
  background-color: lightgray;
  line-height: 24px !important;
  height: 24px !important;
}

.tabContent {
  font-size: 12px !important;
  background-color: lightgray !important;
  color: lightgray !important;
  height: 230px !important;
  align: center !important;
  width: 1180px !important;
}

/* override primereact stuff here */
.p-inputtext {
  width: 36px !important;
  height: 22px !important;
  font-size: 12px !important;
  padding: 1px !important;
  text-align: left !important;
}

body .p-button {
  font-size: 8px !important;
}

.selectPlacement {
  width: 148px !important;
  font-weight: normal !important;
  font-size: 13px !important;
}

.selectPlacementSmall {
  width: 130px !important;
  font-weight: normal !important;
  font-size: 13px !important;
}

.menuItemHeader {
  align: right !important;
  text-align: right !important;
  padding: 2px !important;
  font-weight: bold !important;
  font-size: 13px !important;
  color: gray !important;
  direction: rtl !important;
}

.menuItem {
  align: right !important;
  text-align: right !important;
  padding: 1px !important;
  font-size: 11px !important;
  direction: rtl !important;
}

.ltrMenuItem {
  align: left !important;
  text-align: left !important;
  padding: 1px !important;
  font-size: 11px !important;
  direction: ltr !important;
}

.menuItemSpecial:active, .menuItemSpecial:focus {
    background-color: #e0e0e0 !important
}

.menuItemSpecial:hover {
    background-color: #cecece !important
}

.letterMenuItem {
  align: center !important;
  text-align: center !important;
  padding: 1px !important;
  font-size: 11px !important;
  direction: rtl !important;
}

.selectRange {
  width: 120px !important;
  font-weight: normal !important;
  font-size: 13px !important;
}

.selectRangeSmall {
  width: 80px !important;
  font-weight: normal !important;
  font-size: 13px !important;
}

.placementGridLine {
    text-align: right;
    font-size: 13px;
  font-weight: bold !important;
  background-color: #E0F7FF;
}

.rangeGridLine {
    text-align: right;
    font-size: 13px;
  font-weight: bold !important;
  background-color: #FFE0B0;
}

.gridSwitch {
  width: 32px !important;
}

.tanach-viewer-switchBase{
  height: 11px !important;
}

.tanach-viewer-resize-button {
  padding: 0 !important;
  min-width: 25px !important;
  vertical-align: -6px !important;
  border-radius: 0px !important;
}

.tanach-viewer-resize-buttons {
  background-color: #f8ddc5;
}

.midRange {
  font-weight: bold !important;
  font-size: 18px !important;
  text-align: center !important;
}

.ag-body {
  align: center !important;
}

/* hack to enable cell selection with this https://github.com/ag-grid/ag-grid/issues/87   also see grid options enableCellTextSelection which might be newer feature or might be enterprise feature */
div.ag-root {
-webkit-user-select:text;
-moz-user-select:text;
-ms-user-select:text;
user-select:text;
}

.selectIncludeExclude {
  width: 480px !important;
  height: 40px !important;
  font-weight: normal !important;
  font-size: 28px !important;
  font-weight: bold !important;
  padding: 7px !important;
}

.boxIncludeExclude {
  float: right !important;
  width: 480px !important;
}

.selectIncludeExcludeLetter {
  width: 55px !important;
  height: 40px !important;
  font-weight: normal !important;
  font-size: 28px !important;
  font-weight: bold !important;
  padding: 7px !important;
}

.boxIncludeExcludeLetter {
  width: 55px !important;
  display: inline-block !important;
}

.selectIncludeExcludeGeneral {
  width: 55px !important;
  height: 40px !important;
  font-weight: normal !important;
  font-size: 28px !important;
  font-weight: bold !important;
  padding: 7px !important;
}

.boxIncludeExcludeGeneral {
  width: 55px !important;
  display: inline-block !important;
}


.lntPickerSelectIcon {
  font-weight: bold !important;
  font-size: 36px !important;
  padding: 7px !important;
}

.lntPickerSelectName {
  font-size: 14px !important;
}

.lntPickerSelectType {
  font-size: 10px !important;
}

.emetStyle {
  color: #40A0FF !important;
}

.boxTransformations {
  align: center !important;
  width: 130px !important;
  line-height: 24px !important;
  height: 24px !important;
}

.selectTransformation {
  align: center !important;
  width: 120px !important;
  height: 16px !important;
  font-weight: normal !important;
  font-size: 14px !important;
  font-weight: bold !important;
  padding: 7px !important;
}

.distancesGridLine {
  align: center !important;
  text-align: center !important;
  font-size: 14px;
  font-weight: bold !important;
  background-color: #E0F7FF;
}

.selectDistances {
  align: center !important;
  width: 100px !important;
  height: 16px !important;
  font-weight: normal !important;
  font-size: 14px !important;
  font-weight: bold !important;
  padding: 7px !important;
}

.boxDistances {
  align: center !important;
  width: 130px !important;
  line-height: 24px !important;
  height: 24px !important;
}

.distanceBox {
  direction: rtl !important;
  background-color: #F7F7F7 !important;
  align: center !important;
  text-align: center !important;
  font-size: 11px !important;
  width: 65px !important;
  line-height: 24px !important;
  height: 24px !important;
}

[contenteditable] {
  outline: 0px solid transparent;
}


.wideSliderDisplayBox {
  direction: rtl !important;
  background-color: #F7F7F7 !important;
  align: center !important;
  text-align: center !important;
  font-size: 11px !important;
  width: 82px !important;
  line-height: 24px !important;
  height: 24px !important;
}

.rc-slider-rail {
  background-color: #61baff !important;
}

.rc-slider-handle {
  position: absolute;
  margin-left: 0px !important;
  width: 14px !important;
  height: 14px !important;
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: grab;
  border-radius: 50%;
  border: solid 2px #61baff !important;
}

.rightBarSlider .rc-slider-rail {
  height: 4px !important;
  /* left: 13px !important; */
  width: 40px !important;
  background-color: #b87333 !important;
}

.rightBarSlider .rc-slider-track {
  background-color: #b87333 !important;
}

.rightBarSlider .rc-slider-step {
  /* left: 55px !important; */
}

.rightBarSlider .rc-slider-mark {
  top: -4px !important;
  left: 0px !important;
  width: 90px !important;
  direction: ltr !important;
}

.rightBarSlider .rc-slider-mark-text {
  color: #b87333 !important;
}

.rightBarSlider .rc-slider-handle {
  position: absolute;
  width: 14px !important;
  height: 14px !important;
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: grab;
  border-radius: 50%;
  border: solid 2px #b87333 !important;
}

.placementSlider .rc-slider-mark {
  display: inline-block !important;
  width: auto !important;
}

.MuiButton-label-211{
    height: 28px;
}

.statisticsPickerDropdownButton {
    justify-content: start !important;
    direction: rtl !important;
    padding: 4px 2px 4px 4px !important;
    min-width: 55px !important;
    line-height: 1 !important;
    height: 16px !important;
    margin-right: 0px !important;
    background-color: #3f51b5 !important;
    text-transform: capitalize !important;
}

.nikudTaamDropdownButton {
    justify-content: start !important;
    direction: rtl !important;
    padding: 4px 2px 4px 4px !important;
    min-width: 55px !important;
    line-height: 1 !important;
    height: 39px !important;
    margin-right: 0px !important;
    background-color: white !important;
}

.pasukSettingsDropdownButton {
    justify-content: start !important;
    direction: rtl !important;
    padding: 4px 2px 4px 4px !important;
    min-width: 55px !important;
    line-height: 1 !important;
    height: 30px !important;
    margin-right: 0px !important;
    background-color: white !important;
}

.longNikudTaamDropdownButton{
    min-width: 480px !important;
}

.nikudTaamDropdownButton:hover, .nikudTaamDropdownButton:active{
    border-bottom-style: solid !important;
    border-width: 1px !important;
    border-color: black !important;
    padding-bottom: 3px !important;
}

.nikudTaamDropdownButton:focus {
    outline: none !important;
    border-bottom-style: solid !important;
    border-bottom-width: 1px !important;
    border-bottom-color: blue !important;
    padding-bottom: 3px !important;
}

#text-comparator-grid .ag-header-cell-label .ag-header-cell-text{
    white-space: normal !important;
    line-height: 1;
    overflow-wrap: normal;
}

.singleAddSubtractButton {
    outline: none !important;
    display: inline;
}

.save-settings-link:hover{
    text-decoration: none !important;
}

.save-results-link:hover{
    text-decoration: none !important;
}

.disabled-font-icon {
    opacity: 0.4;
    filter: alpha(opacity=40); /* msie */
}

.MuiTabs-scrollButtonsAuto-365{
    max-width: 20px !important;
    min-height: 20px !important;
}

.MuiTabs-scroller-361.MuiTabs-scrollable-363{
    overflow: hidden !important;
    margin-bottom: 0px;
}

.close-tab-button{
    padding: 0px !important;
    max-width: 15px !important;
    max-height: 15px !important;
    min-width: 15px !important;
    font-size: 13px !important;
    margin-bottom: 4px !important;
    margin-right: 15px !important;
    color: white !important;
}

.close-tab-button:hover{
    background-color: #c2cbff !important;
    color: black !important;
}

.close-tab-button-icon{
    max-width: 20px !important;
    min-width: 20px !important;
    width: 20px !important;

}

.MuiTab-labelContainer-377{
    height: 20px;
}

.search-results-tab>span, .search-results-tab>span>span {
    padding: 0 !important;
}

.search-results-tab{
    line-height: 0 !important;
    padding: 0 !important;
    min-width: 0px !important;
    min-height: 20px !important;
}

.search-results-tab:hover{
    background-color: #428afc !important;
}

.search-results [class*="MuiTabs-scrollable"]{
    overflow-x: hidden;
}

.search-results [class*="MuiTabs-scroller"]{
    max-height: 20px !important;
    border-color: white;
}

.search-results [class*="MuiPrivateTabIndicator"]{
    background-color: white;
    height: 1px;
}

.search-results [class*="MuiPrivateTabScrollButton"]{
    width: 30px;
}


.results-status-bar {
    background-color: #3f51b5 !important;
    width: 100% !important;
    max-height: 23px !important;
    z-index: 1 !important;
    position: relative !important;
    height: 23px !important;
    margin: 0px 0px 0px !important;
    padding: 3px 20px !important;
    display: flex;
    justify-content: center;
}
.totalPesukimTooltipHeader{
  background-color: lightgrey;
  font-weight: bold;
  font-size: 14px;
  position: -webkit-sticky;
  position: sticky;
  top:0;
}
.bold{
  font-weight: bold;
}
.totalPesukimTooltipHeader td{
  min-width: 82px;
}
.totalPesukimTooltipHeader :hover{
  cursor: pointer;
}
.totalPesukimTooltip{
  text-align: right;
  max-height: 200px;
  overflow-y: auto;
  font-size: 12px;
  outline: rgb(189, 189, 189) 2px solid;
  outline-offset: 4px;
  background-color: white;
  line-height: 1.2;
}
.blank_row{
  height: 10px !important;
}
.arrowIcon{
  font-size: 20px !important;
}

.results-status-bar-text{
    color: yellow !important;
    line-height: 1 !important;
    position: absolute;
    right: 0px;
    top:2px;
}
.results-status-bar-text :hover{
  cursor: pointer;
}
.results-status-bar-text.noHover :hover{
  cursor:auto;
}


.results-per-page-select{
  color: white !important;
  line-height: 1 !important;
  position: absolute !important;
  max-height: 15px !important;
  left: 15px !important;
  display: flex !important;
  font-size: 14px !important;
  font-weight: bold !important;
}

.hoverCheckbox {
  vertical-align: middle;
  position: relative;
  bottom: 1px;
}

.result-statistics-select{
  z-index: 1 !important;
  color: white !important;
  line-height: 1 !important;
  position: absolute !important;
  height: 10px !important;
  max-height: 15px !important;
  left: 10px !important;
  display: flex !important;
  font-size: 12px !important;
  font-weight: bold !important;
  margin-top: 3px !important;
}

#search-results-app-bar{
    width: 100%;
    max-width: 1400px;
    max-height: 20px;
    min-height: 0px;
    z-index: 1;
    position: relative;
}

#keep-results-open{
    position: relative !important;
    z-index: 1 !important;
    min-width: 80px !important;
    padding: 3px !important;
}

.search-results .p-checkbox-icon {
    font-size: 14px !important;
}

.search-results .p-checkbox .p-checkbox-box, .search-results .p-checkbox{
    width: 15px !important;
    height: 15px !important;
}

.search-results-control-divider{
    margin: 3px 0;
}

#advanced-search-table{
    max-width: calc(100vw - 400px) !important;
    min-width: 968px;
    overflow: auto;
}

#home-component{
    min-width: 1368px;
}

/* push SplitPane resizers behind hebrew keyboard */
.sc-bwzfXH.hEACtv.sc-bdVaJa.cjjWdp {
    z-index: 0;
}

.context-menu-checkbox {
    opacity: 1 !important;
}

.context-menu-LNT-icon{
    padding: 0px 6px;
    font-size: 18px !important;
    line-height: 1.3;
    display: inline-block;
}
.noBorder{
  border:none !important;
  padding: 0px !important;
  margin:0;
}

.context-menu{
    min-width: 300px;
}

.taam-parser-selector-paper{
  padding: 5px;
  margin: 5px;
  display: inline;
}

#taam-parser-font-key{
    font-family: arial, verdana, tahoma;
    text-align: right;
    position: absolute;
    right: 0;
    top: 0;
    right: 0;
    z-index: 1;
    width: 300px;
    background-color: lightgrey;
    border-bottom-left-radius: 5px;
    line-height: 1px;
}

#taam-parser-font-key td {
    padding: 4px;
}

.taam-parser-background-key-label{
    display: inline !important;
    padding: 3px;
    margin: 3px !important;
    font-weight: 600 !important;
    border-radius: 5px;
}

#taam-parser-key{
    text-align: center;
    padding-right: 5px;
    font-size: 15px;
    max-width: 800px;
    background-color: #e8e8e8;
    margin-top: 5px;
    direction: rtl;
}

.taam-parser-font-key-label{
    background-color: lightgray;
    display: inline !important;
    padding: 3px;
    margin: 3px !important;
    font-weight: 600 !important;
    border-radius: 5px;
}

#keisar-font-key-label{
    color: #000000;
    font-family: arial, verdana, tahoma !important;
    font-size: 14px;
    padding: 2px 3px;
    font-weight: 800 !important;
}

.taam-parser-dialog-content{
    width: 80vw;
    position: relative;
}

.taamParserMenuItemHeader {
  align: right !important;
  text-align: right !important;
  padding: 2px !important;
  font-weight: bold !important;
  font-size: 13px !important;
  color: gray !important;
  direction: rtl !important;
}

.taamParserMenuItem {
  align: right !important;
  text-align: right !important;
  padding: 1px !important;
  padding-right: 5px !important;
  font-size: 15px !important;
  direction: rtl !important;
}

.taamParserSelectorBuffer{
    width: 5px;
    display: inline;
}

.taam-parser-left-nav-buttons{
    display: inline;
    background-color: lightgrey;
    position: absolute;
    left: 10px;
    border-radius: 12px;
}

.taam-parser-right-nav-buttons{
    display: inline;
    background-color: lightgrey;
    position: absolute;
    right: 10px;
    border-radius: 12px;
}


.tanach-viewer-nav-buttons{
  display: inline;
  background-color: lightgrey;
  position: absolute;
  border-radius: 12px;
  top: 31px;
}

.tanach-viewer-left-nav-buttons{
  left: 10px;
}

.tanach-viewer-right-nav-buttons{
  right: 10px;
}



.parsha-viewer-text-cell{
    background-color: white;
    position: relative;
    line-height: 1.5;
    overflow: auto;
    white-space: normal;
    height: 100%;
    width: 100% !important;
    border: 2px solid grey;
    box-sizing: border-box;
    text-align: right;
    padding: 5px;
    display: flex;
}

.parsha-viewer-text-stream{
  font-family: SBL Hebrew;
  margin: 0 10px;
}

.parsha-viewer-gematria-stream{
  font-family: SBL Hebrew;
  margin: 0 10px;
}

.parsha-viewer-atbash-stream{
  color: #545454;
  font-family: SBL Hebrew;
  margin: 0 10px;
}

.parsha-viewer-text-cell > * {
  text-align: justify;
}

.parsha-viewer-stam-stream{
  font-family: "StamAshkenazCLM";
  margin: 0 10px;
}

.parsha-viewer-grid .ag-full-width-row{
    padding-right: 17px !important;
    overflow: auto !important;
}
.search-results-button-cell{
  font-weight: 800 !important;
  padding: 0 !important;
  background-color: #d3d3d3;
  border-radius: 7px;
  cursor: pointer;
  font-size: 18px;
  width: 18px !important;
  height: 18px !important;
  margin: 3px;
  -webkit-transition: .25s;
  transition: .25s;
}
.search-results-button-cell-text{
  vertical-align: middle;
  line-height: .85;
background-color: #d3d3d3;
}

.parsha-viewer-button-cell{
    font-weight: 800 !important;
    padding: 0 !important;
    background-color: #d3d3d3;
    border-radius: 7px;
    cursor: pointer;
    font-size: 18px;
    width: 18px !important;
    height: 18px !important;
    margin: 3px;
    -webkit-transition: .25s;
    transition: .25s;
}

.parsha-viewer-button-cell:hover{
    background-color: #707070;
    color: white;
}

.parsha-viewer-button-cell-text{
    vertical-align: middle;
    line-height: .85;
}

.tanachViewerToggleButtonGroup{
    display: inline-flex;
}
.searchResultsToggleButtonGroup{
  display: inline;
  /* line-height: 1 !important; */
  direction: 'rtl';
  max-height: 19px !important;
}

.parshaViewerToggleButtonGroup{
  vertical-align: -2px;
  display: inline-flex;
}
.searchResultsToggleButtonContainer{
  width: 150px !important;
  display: inline;  
  /* line-height: 1 !important; */
  max-height: 19px !important;
}

.tanachViewerToggleButtonContainer{
    width: 150px !important;
    display: inline;
}

.parsha-viewer-select-text-display-type{
  margin: 0px 5px;
  display: inline;
  white-space: nowrap;
}

.tanach-viewer-select-text-display-type{
  margin: 0px 4px;
  display: inline;
  white-space: nowrap;
}
.search-results-select-text-display-type{
  margin: 0px 4px;
  display: inline;
  white-space: nowrap;

}
.parsha-viewer-text-table{
    width: 100%;
}

.parsha-viewer-text-table, .parsha-viewer-text-table tr, .parsha-viewer-text-table td, .parsha-viewer-text-table th  {
    border-color: #d7d9db !important;
    border: 1px solid;
    border-top-style: solid;
    border-top-width: 1px;
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-style: solid;
    border-left-width: 1px;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.parsha-viewer-text-table-makor{
    background-color: #d3d3d3;
    font-weight: bold !important;
    padding: 0 11px;
    width: 85px;
    vertical-align: top;
}

.parsha-viewer-text-table-text{
    padding: 2px 11px;
}

.parsha-viewer-text-table th{
    background-color: #707070;
    color: white;
    padding: 0 12px;
    line-height: 32px;
    font-size: 16px;
    font-weight: 600;
    border-color: #7f8081 !important;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
}

.tanach-viewer-text-table{
  width: 100%;
  background-color: white;
  color: rgba(0, 0, 0, 1) !important;
}

.tanach-viewer-text-table, .tanach-viewer-text-table tr, .tanach-viewer-text-table td, .tanach-viewer-text-table th  {
  border-color: #d7d9db !important;
  border-right-style: solid;
  border-right-width: 1px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-style: solid;
  border-left-width: 1px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif !important;
  vertical-align: top;
}

.tanach-viewer-text-table-header{
  background-color: #707070;
  color: white;
  font-weight: bold !important;
  padding: 5px 11px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.tanach-viewer-text-table-makor{
  background-color: #d3d3d3;
  font-weight: bold !important;
  padding: 0 11px;
  white-space: nowrap;
  vertical-align: top;
}

#tanach-viewer-text-display{
  position: relative;
}

#distance-settings-disabled-message-parent{
    display: table;
    width: calc(100% - 900px);
    margin: 10px 10px 10px 90px;
    height: 70px;
    float: left;
    background-color: darkgrey;
    font-weight: bold;
    color: blue;
    cursor: default;
}
#distance-settings-disabled-message{
    display: table-cell;
    vertical-align: middle;
    text-align: center !important;
}

#advanced-search-table [class*="MuiButtonBase-disabled"]{
    color: #1e1c1b !important;
}
#advanced-search-table [class*="MuiToggleButton-disabled"]{
    color: #a0a0a0 !important;
}

.disabled-toggle{
    box-shadow: none !important;
}

.primeFacesAppMenuIcon{
    vertical-align: text-bottom !important;
}

.appMenuIcon{
    min-width: 30px !important;
    display: inline;
}

.app-nav-divider{
    background-color: lightgray;
    height: 1px;
    width: 100%;
    margin: auto;
}

.ag-grid-placement-row{
    /* using this to fix display issue with adv search placement tab when lang is set to english */
    width: 101% !important;
}

.navbar-half-pasuk-div{
    width: 115px;
    height: 100%;
    padding: 28px 0;
    line-height: 1.5;
    color: #d5d5d5;
    font-size: 15px;
}

.language-toggle{
    display: inline-block;
}

.nav-bar-side-cell{
    width: calc(50vw - 280px);
    min-width: 404px;
}

.tanach-viewer-text-stream{
    text-align: justify;
    font-family: SBL Hebrew;
    font-size: 18px;
    color: black;
    direction: rtl;
    max-width: 100%;
    max-height: 100%;
    cursor: text;
    padding-left: 10px;
    display: flex;
}

.tanach-viewer-content{
  cursor: text;
}

.search-results-page-button{
  line-height: 1 !important;
  font-size: 12px !important;
  padding: 0px 8px !important;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  min-width: 0 !important;
  margin: 0 4px !important;
}

.search-results [class*="MuiSelect-icon"]{
  color: white;
}

.username-display{
  max-width: calc(50vw - 675px);
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  direction: ltr;
}

.nav-bar-right-side{
  direction: rtl;
  padding-right: 15px;
  display: flex;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nav-bar-left-side{
  padding-left: 15px;
}

#navbar-right-items-parent{
  white-space: nowrap;
}

.navbar-right-item{
  display: inline;
}

#main-menu-outer-div{
  display: flex;
  justify-content: right;
}

.main-menu-panel{
  padding: 5px;
}


@media only screen and (max-width: 1625px) {
  .navbar-disappering-items{
    display: none !important;
  }
  .username-display{
    max-width: calc(100% - 260px);
  }
}

#paneContainer .css-glamorous-div--hvdu2p{
  overflow: hidden !important;
}

#tanach-viewer-fetch-pesukim-button{
  min-width: 0 !important;
  padding: 0 !important;
  height: 25px !important;
  font-size: 14px !important;
  margin: 1px !important;
}

.parsha-viewer-toggle-button {
  padding: 0 7px !important;
  height: 25px !important;
  min-width: 0 !important;
}

.text-comparator-viewer-toggle-button{
  padding: 0 7px !important;
  height: 20px !important;
  min-width: 0 !important;
}

.tanach-viewer-content-toggle-button {
  padding: 0 3px !important;
  height: 25px !important;
  min-width: 0 !important;
}
.search-result-toggle-button{
  padding: 0 !important;
  max-height: 19px !important;
  height: 19px !important;
  font-size: 14px !important;
  outline-width: 0px !important;
}

.tanach-viewer-display-toggle-button {
  padding: 0 6px !important;
  height: 25px !important;
  min-width: 0 !important;
}

.parsha-viewer-control-button{
  padding: 0 7px !important;
  height: 25px !important;
  min-width: 0 !important;
}
.closeOpenPesukimButton{
  margin: 5px ;
  padding:0 !important;
  font-size: 12px !important;
  height: 25px !important;
  width: 70px !important;
}

.tanach-viewer-placement-selector{
  text-align: right;
  font-size: 13px;
  font-weight: bold !important;
}

.tanach-viewer-placement-selector-select-parent{
  white-space: nowrap;
}

.tanach-viewer-placement-selector-parent{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  max-width: calc(100% - 120px);
  line-height: 1;
  height: 40px;
}

.tanach-viewer-placement-select{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.tanach-viewer-placement-select-root{
  width: 110px !important;
  font-weight: normal !important;
  font-size: 13px !important;
}

.tanach-viewer-type-switch-parent-span-en *{
  justify-content: unset !important;
}

.tanach-viewer-stam-stream{
  font-family: "StamAshkenazCLM";
  text-align: justify;
}

.tanach-viewer-ivri-stream{
  font-family: "Hebrew-Paleo-Gezer";
  text-align: justify;
  line-height: 20px;
  padding-top: 4px;
}

.parsha-viewer-ivri-stream{
  font-family: "Hebrew-Paleo-Gezer";
  text-align: justify;
  line-height: 20px;
  margin: 0 10px;
  padding-top: 4px;
}

.ivri-word-span{
  padding-top: 11px;
  padding-bottom: 4px;
  line-height: 1.5;
  letter-spacing: -2.5px;
  word-spacing: -2.5px;
}

.ivri-pasuk-span{
  padding-top: 11px;
  padding-bottom: 4px;
  line-height: 1.5;
  letter-spacing: -2.5px;
  word-spacing: -2.5px;
}

.tanach-viewer-stream-panel{
  margin: 0 15px;
}

.tanach-viewer-atbash-stream{
  color: #545454;
}

.text-cell-pasuk-span{
  -webkit-transition: background-color .25s;
  transition: background-color .25s;
}

.tanach-viewer-selector-button{
  margin: 0px !important;
  /* color: blue !important; */
  height: 20px;
  padding: 0 !important;
  vertical-align: -4px !important;
  outline: none !important;
  cursor: pointer !important;
}

.tanach-viewer-selector-button-icon{
  font-size: 15px !important;
}


.context-results-row{
  box-sizing: border-box;
  display: inline-block;
  overflow-y: auto !important;
  position: absolute;
  /* line-height: 1.5 !important; */
  white-space: normal !important;
}

.placement-selector-2D-label{
  font-size: 13px !important;
  line-height: 1;
  text-transform: lowercase;
  max-width: 83.5px;
  min-width: 83.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  direction: rtl;
}

.text-comparator-options-select-label{
  font-size: 13px !important;
  line-height: 1;
  text-transform: lowercase;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-comparator-options-select-label::first-letter{
  text-transform: capitalize;
}

.placement-selector-2D-letter-label{
  font-size: 13px !important;
  line-height: 1;
  text-transform: lowercase;
  max-width: 45px;
  min-width: 45px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.placement-selector-2D-label-tanach-viewer-named-section{
  font-size: 13px !important;
  line-height: 1;
  text-transform: lowercase;
  max-width: 75px;
  min-width: 75px;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: rtl;
}

.placement-selector-2D-button{
  max-height: 36px;
  padding: 0 3px !important;
  background-color: unset !important;
  outline: unset !important;
  vertical-align: 1px !important;
  min-width: 0 !important;
}

.placement-selector-2D-icon{
  color: #757575 !important;
}

.placement-selector-2D-popover [class*="MuiPopover-paper"]{
  padding: 5px !important;
}

.placement-selector-2D-columns-parent-ltr{
  display: flex;
  direction: ltr;
  overflow: auto;
}

.placement-selector-2D-columns-parent-rtl{
  display: flex;
  direction: rtl;
  overflow: auto;
}

.placement-selector-2D-menuItem-ltr{
  align: left !important;
  text-align: left !important;
  padding: 1px !important;
  font-size: 13px !important;
  padding: 1px 5px !important;
}

.placement-selector-2D-menuItem-rtl{
  align: right !important;
  text-align: right !important;
  padding: 1px !important;
  font-size: 13px !important;
  padding: 1px 5px !important;
}

.placement-selector-2D-selector{
  padding: 0 5px;
}

.menuItemHeader-rtl {
  align: right !important;
  text-align: right !important;
  padding: 2px !important;
  font-weight: bold !important;
  font-size: 13px !important;
  color: gray !important;
  direction: rtl !important;
}

.menuItemHeader-ltr {
  align: right !important;
  text-align: right !important;
  padding: 2px !important;
  font-weight: bold !important;
  font-size: 8px !important;
  color: gray !important;
  direction: ltr !important;
}

.statsSortOrder-selector-2D-label-rtl {
  font-size: 13px !important;
  line-height: 1;
  text-transform: capitalize;
  max-width: 85px;
  min-width: 85px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  direction: rtl;
}

.statsSortOrder-selector-2D-label-ltr {
  font-size: 12px !important;
  line-height: 1;
  text-transform: capitalize;
  max-width: 110px;
  min-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  direction: ltr;
}

.statsSortOrder-selector-2D-button{
  max-height: 23px;
  padding: 0 3px !important;
  color: #303030 !important;
  background-color: #b87333 !important;
  outline: unset !important;
  vertical-align: 1px !important;
  min-width: 0 !important;
  vertical-align: middle !important;
  width: 150px;
}

.statsSortOrder-selector-2D-icon{
  color: #303030 !important;
}

.statsSortOrder-selector-2D-popover [class*="MuiPopover-paper"]{
  padding: 5px !important;
}

.statsSortOrder-selector-2D-columns-parent-ltr{
  display: flex;
  direction: ltr;
  overflow: auto;
}

.statsSortOrder-selector-2D-columns-parent-rtl{
  display: flex;
  direction: rtl;
  overflow: auto;
}

.statsSortOrder-selector-2D-menuItem-ltr{
  align: left !important;
  text-align: left !important;
  padding: 1px !important;
  font-size: 13px !important;
  padding: 1px 5px !important;
}

.statsSortOrder-selector-2D-menuItem-rtl{
  align: right !important;
  text-align: right !important;
  padding: 1px !important;
  font-size: 12px !important;
  padding: 1px 5px !important;
}

.statsSortOrder-selector-2D-selector{
  padding: 0 5px;
}

.LCS-distance-label{
  display: inline-block !important;
  width: 32px !important;
}

.LCS-distance-slider{
  display: inline-block !important;
  width: 110px !important;
}

.LCS-word-length-slider{
  display: inline-block !important;
  width: 50px !important;
}

.LCS-words-to-skip-textfield{
  padding: 4.5px 7px !important;
  font-size: 15px !important;
  direction: rtl;
  text-align: right;
}

.LCS-other-words-label{
  margin-top: 3px !important;
  font-size: 12px !important;
}

.LCS-select-menu-item{
    padding: 2px !important;
    font-weight: bold !important;
    font-size: 13px !important;
    color: gray !important;
}

.LCS-fab-parent{
  display: inline-block;
}

.LCS-search-setting-parent-table tr:not(:last-child){
  border-bottom: 1px solid white;
}

.LCS-open-settings-button{
  max-height: 36px;
  padding: 0 3px !important;
  outline: unset !important;
  min-width: 0 !important;
  vertical-align: -8.5px !important;
}

.LCS-search-settings-popover-paper{
  background-color: #ffe1c5  !important;
}

.diff-viewer-remove-white-space-root{
  padding: 0 !important;
}

.diff-viewer-remove-white-space-label{
  margin: 0 !important;
  vertical-align: -7px !important;
}

.diff-viewer-remove-white-space-label *{
  font-weight: bold !important;
}

.css-cncyp1-word-diff{/*diff segment*/
  padding: 0 1px;
}

.css-o1u8iu-content-text{ /*diff cell*/
  font-family: Arial;
  font-size: 14px;
  word-spacing: 5px;
}

.css-1ks9aww-gutter:not(:first-child){/*diff source cell*/
  min-width: unset !important;
  -webkit-user-select: unset !important;
     -moz-user-select: unset !important;
      -ms-user-select: unset !important;
          user-select: unset !important;
  cursor: auto !important;
  cursor: initial !important;
}

.css-1ks9aww-gutter:first-child{/*diff numer cell*/
  min-width: unset !important;
}

[class*="diff-container"], [class*="diff-container"] *{
  direction: rtl !important;
  text-align: right;
}

.diff-viewer-location-cell{
  font-weight: bold;
  font-family: "Arial";
  font-size: 14px;
  white-space: nowrap;
}

table.css-1n5o7vh-diff-container td {
  border: 2px solid #e4e4e4;
}

.css-1ks9aww-gutter.css-rq9a2a-diff-removed {
  background: #dce2ff !important;
}

.css-hf3w1f-word-removed {
  background: #b8c1fd !important;
  font-weight: bold;
}

.css-1u4zuq6-word-added{
  font-weight: bold;
}

.css-rq9a2a-diff-removed {
  background: #eeefff !important;
}

.css-1ks9aww-gutter .css-1klnsbn-empty-gutter .css-rq9a2a-diff-removed{
  background: unset !important;
}

.css-1ks9aww-gutter.css-cnnxkz-diff-added {
  background: #cdffd8 !important;
}

.diff-table-header-cell{
  height: 30px;
  font-family: "Arial";
  font-weight: bold;
  font-size: 14px;
  background: lightgray;
  padding: 10px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}

.independent-scroll-diff-parent-table{
  direction: rtl;
  width: 100%;
}

.independent-scroll-diff-parent-table .css-1ks9aww-gutter.css-rq9a2a-diff-removed,
.independent-scroll-diff-parent-table .css-1ks9aww-gutter.css-cnnxkz-diff-added
{
  width: .1%
}

.diff-viewer-full-same-span{
  background: #d2b8fd;
  padding: 2px;
  font-weight: bold;
  display: inline-flex;
}

.diff-perfect-match-cell pre{
  background: #d2b8fd !important;
  font-weight: bold;
}

.empty-diff-cell{
  border: 0px solid !important;
  background-color: unset !important;
}

.diff-viewer-loading-parent ._loading_overlay_wrapper._loading_overlay_wrapper--active.css-79elbk{
 height: 100% !important;
}

.betaText {
  font-family: Bookman Old Style, Courier New !important;
  font-weight: bold !important;
  font-style: italic !important;
  font-size: 31px !important;
  color: #61baff !important;
  line-height: 30px !important;
}

.baseHaseferText {
  font-family: Bookman Old Style, Courier New !important;
  font-weight: bold !important;
  font-style: italic !important;
  font-size: 24px !important;
  color: #61baff !important;
  line-height: 30px !important;
}
.betaNotice{
  font-family: Rockwell;
  color:red;
  display:inline-block;
  padding:0 55px;
  opacity: 1;
	-webkit-animation-name: fadeInOpacity;
	        animation-name: fadeInOpacity;
  -webkit-animation-duration: 15s;
          animation-duration: 15s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}
.container_row{
  display: grid;
}
.layer1{
  grid-column: 1;
  grid-row: 1;
  font-weight: bold;
  font-size:36px;
  -webkit-transform: scale(1.5,1.5);
  transform: scale(1.3,1.3);
}
.betaNoticeSub{
  grid-column: 1;
  grid-row: 1;
  padding: 19px 0 0 11px;
  /* -webkit-transform: scale(.8,1);
  -moz-transform: scale(.8,1);
  -ms-transform: scale(.8,1);
  transform: scale(.9,1); */
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 11px;
  font-weight: normal;
}
@-webkit-keyframes fadeInOpacity {
  0% { opacity: 0; }
  8% { opacity: 1;}
  92% { opacity: 1;}
  100% { opacity: 0; }
}
@keyframes fadeInOpacity {
  0% { opacity: 0; }
  8% { opacity: 1;}
  92% { opacity: 1;}
  100% { opacity: 0; }
}


.betaUpsideDown {
  font-family: Bookman Old Style, Courier New !important;
  font-weight: bold;
  font-style: italic;
  font-size: 31px;
  color: #1e90ff;
  line-height: 30px;
  display: inline-block;
  -webkit-transform-origin: 13.55px 14.2px;
          transform-origin: 13.55px 14.2px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.navbar-messages-parent{
  display: flex;
}

.navbar-server-messages-parent{
  border: solid 2px dodgerblue;
  margin-left: 14px;
  flex-grow: 1;
  height: 80px;
  overflow: auto;
  border-radius: 10px;
  padding: 5px;
  color: salmon;
  font-size: 13px;
  font-style: italic;
  font-weight: bold;
  word-break: break-word;
}

.beta-test-message-parent{
  width: 120px;
}

.object-cloud-parent > div{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
}

.pasuk-settings-slider-parent {
  width: 370px;
}

.parts-of-speech-settings-table, .parts-of-speech-settings-table td{
  /* border: 3px solid black; */
}

.parts-of-speech-dropdown-label{
  /* background-color: #f7f7f7; */
  display: inline-block;
  width: 75px;
  text-align: left;
  font-weight: bold;
  /* border: 1px solid #9f9f9f; */
  margin: 0px 4px 0px -1px;;
  vertical-align: middle !important;
}

.placement-dropdown-label{
  /* background-color: #f7f7f7; */
  display: inline-block;
  width: 75px;
  text-align: left;
  font-weight: bold;
  /* border: 1px solid #9f9f9f; */
  margin: 0px 4px 0px -1px;;
  vertical-align: middle !important;
}

.parts-of-speech-selector-2D-button{
  max-height: 23px;
  padding: 0 3px !important;
  background-color: white !important;
  outline: unset !important;
  vertical-align: 1px !important;
  min-width: 0 !important;
  vertical-align: middle !important;
  width: 120px;
}

.parts-of-speech-dropdown-parent{
  margin-bottom: 2px;
}

.placement-separator{
  font-size: 3px !important;
  height: 3px !important;
  line-height: 3px !important;
}

.parts-of-speech-separator{
  font-size: 14px !important;
  height: 10px !important;
  line-height: 10px !important;
}

.parts-of-speech-settings-table-cell{
  vertical-align: top;
  /* padding-left: 10px; */
}

.parts-of-speech-slider-label{
  /* background-color: #f7f7f7; */
  display: inline-block;
  width: 110px;
  text-align: left;
  font-weight: bold;
  /* border: 1px solid #9f9f9f; */
  margin: 0px 4px 0px -1px;;
  vertical-align: middle !important;
}

.parts-of-speech-hagdara-klalit-label{
  display: inline-block;
  width: 40px;
  line-height: 10px;
  margin-bottom: -3px;
  overflow-y: hidden;
}

.socialButtonGroup{
  display: inline !important;
  vertical-align: middle !important;
}

.socialButtonGroup-item{
  display: inline !important;
  padding: 0px !important;
  width: 30px;
  height: 30px;
}

.sortOrderDiv .rc-slider {
    align: center !important;
    text-align: center !important;
    width: 15px !important;
    padding-top: 0px !important;
    margin-top: 30px !important;
    margin-right: 30px !important;
    margin-bottom: 30px !important;
}

.sortOrderDiv .rc-slider-rail {
  background-color: #b87333 !important;
}


.sortOrderCaption {
    width: 57px !important;
}

.sortOrder-slider-wrapper-div {
    align: center !important;
    text-align: center !important;
    width: 15px !important;
    display: grid !important;
    grid-columns-template: 1fr 3fr;
}

.sortOrder {
    width: 20px !important;
    align: center !important;
}

.sortOrderSelect{
  line-height: 1 !important;
  max-height: 18px !important;
  display: flex !important;
  font-size: 12px !important;
  font-weight: bold !important;
}

.wordHover:hover{
  border-top-style: hidden;
}
.fullLineOfText:hover{
  border-top-style: hidden;
}
.fullLineOfText{
  /* line-height: 30px !important; */
}
.theWord{
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 16px;
}

.wordStatTooltip{
  position: absolute;
  background-color: #444444 !important;
  color: white !important;
  width: 680px !important;
  height: 155px !important;
  overflow-wrap: break-word;
  pointer-events: none;
  text-align: right;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
  line-height: 17px;
  padding-right:10px;
  padding-top: 10px;
  /* margin: 10px; */
}
.wordStatsTooltip_en{
  position: absolute;
  background-color: #444444 !important;
  color: #dddddd !important;
  width: 400px !important;
  height: 200px !important;
  margin: 5px;
  padding: 0px !important;
  overflow: hidden;
  pointer-events: none;
  opacity: .89;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;

}

.pasukStatsTooltipBlank {
  width: 0px !important;
  height: 0px !important;
}

.pasukStatsTooltip_he {
  position: absolute;
  background-color: #444444 !important;
  color: #dddddd !important;
  width: 530px;
  height: 175px;
  margin: 5px;
  padding: 0px !important;
  overflow: hidden;
  pointer-events: none;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}

.pasukStatsTooltip_he.ag-tooltip-hiding {
  opacity: 0;
}

.pasukStatsTooltip_he p {
  margin: 5px;
  white-space: nowrap;
}

.pasukStatsTooltip_en {
  position: absolute;
  background-color: #444444 !important;
  color: #dddddd !important;
  width: 580px;
  height: 175px;
  margin: 5px;
  padding: 0px !important;
  overflow: hidden;
  pointer-events: none;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}

.pasukStatsTooltip_en.ag-tooltip-hiding {
  opacity: 0;
}

.pasukStatsTooltip_en p {
  margin: 5px;
  white-space: nowrap;
}

.pasukStatsTooltipLocation {
  color: #61baff !important;
  font-size: 14px;
  font-weight: bold;
}

.react-grid-item.react-grid-placeholder {
  background-color: #ccc!important;
}
.startMenuBox {
  /* display:inline-block; */
  margin: 10px;
  background-color:#ccc;
  /* border:1px solid #ff0000; */
}
.outlined{
  width: 250px;
  border: 2px solid black;
  background-color: white;
  padding: 8px;
  vertical-align: middle;
  text-align: center;
}
.outlined:hover{
  cursor:pointer;
}
.center {
  margin: auto;
  height: 110px;
}
.subtitle{
  max-width: 245px;
  font-size: 12px;
  font-weight:normal;
  line-height: normal;
}
.panel{
  min-height: 100%;
  border: 2px solid black;
  border-radius: 25px;
  background-repeat: no-repeat;
  background-position: center; 
  position: relative;
  font-weight: bold !important;
  display: flex;
}
.panel:hover{
  cursor: move;
}

.parshaViewer{
  background: url(/static/media/panel_parshaViewer.e7d84fa2.jpg);
  background-size: cover;
  background-position:center center;
}
.frequencyViewer{
  background: url(/static/media/panel_frequencyViewer.1ac8fa44.jpg);
  background-size: cover;
  background-position:center center;
}
.tanachViewer{
  background: url(/static/media/panel_tanachViewer.665e54cd.jpg);
  background-size: cover;
  background-position:center center;
}
.lexicon{
  background: url(/static/media/panel_lexicon.216fc475.jpg);
  background-size: cover;
  background-position:center center;
}
  .lexiconKeyRTL{
    background-color: white;
    /* outline: black solid 2px; */
    direction: rtl;
    text-align: right;
    font-size: 14px;
    min-height: 50px;
    margin: 6px 0 7px;
  }
  .lexiconKeyLTR{
    background-color: white;
    /* outline: black solid 2px; */
    direction: ltr;
    text-align: left;
    font-size: 14px;
    min-height: 50px;
    margin: 6px 0 7px;
  }
  .lexiconKeyVerticalPadding{
    height: 50px;
  }
  .clickable{
    cursor: pointer;
  }

  .showLexiconKey{
    display:inline;
    line-height:1;
    margin: 14px 0 4px;
    max-width: 110px;
    float: left;
    min-height:24.625px;
    padding-left: 3px;
    outline:black solid 1px;
  }

.premiumServices{
  background: url(/static/media/panel_premiumServices.fad64bef.jpg);
  background-size: cover;
  background-position:center center;
}
.yuchsinTree{
  background: url(/static/media/panel_yuchsinViewer.6de2f6dc.jpg);
  background-size: cover;
  background-position:center center;
}
.objectClouds{
  background: url(/static/media/panel_cloudViewer.0f8af500.jpg);
  background-size: cover;
  background-position:center center;
}
.taamParser{
  background: url(/static/media/panel_taamParser.759fd932.jpg);
  background-size: cover;  
  background-position:center center;
}
.textComparator{
  background: url(/static/media/panel_textComparator.d24771db.jpg);
  background-size: cover;
  background-position:center center;
}

.goHome{
  float:right !important;
  padding: 4px !important;
  margin-right: 50px;
  margin-bottom: 20px;
  max-width: 100px;
  position:relative;
  /* top: 50%; */
}
.goHome:hover{
  cursor: pointer;
}

.scrollableDiv{
  line-height:normal;
  overflow-y: auto !important;
  max-height:300px;
  background-color: white;
  color: black;
  font-weight: normal;
  font-size: 11px;
}
.popover-search-criteria{
  font-size: 14px;
}

.flex-container {
  display: inline-flex; /* takes only the content's width */
}


.word-stat-row{
  width: 100%;
}

.word-stat-row, .word-stat-row tr, .word-stat-row td, .word-stat-row th  {
  border-color: #d7d9db !important;
  border: 1px solid;
  border-top-style: solid;
  border-top-width: 1px;
  border-right-style: solid;
  border-right-width: 1px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-style: solid;
  border-left-width: 1px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.word-stat-id{
  background-color: #d3d3d3;
  font-weight: bold !important;
  padding: 0 11px;
  width: 45px;
  vertical-align:middle;
}

.word-stat-text{
  white-space: nowrap;
  padding: 0px 11px ;
}

.word-stat-row th {
  background-color: #707070 !important;
  direction: ltr;
  text-align: right;
  color: white;
  padding: 0 12px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  border-color: #7f8081 !important;
  /* min-width: fit-content; */
  white-space: nowrap;
}

.word-stat-row td:first-child, th:first-child {
  position:-webkit-sticky;
  position:sticky;
  right:0;
  min-width: 35px !important;
  max-width: 35px !important;
  z-index:2;
  background-color:#d3d3d3;
}
.word-stat-row td:nth-child(2), th:nth-child(2)  { 
  position:-webkit-sticky; 
  position:sticky;
  right: 35px;
  z-index:2;
  background-color:#d3d3d3;
}


/* .word-stat-row td[scope=row] {
  position: -webkit-sticky;
  position:sticky;
  right: 0;
  z-index: 2;
  /* background-color: white !important; */
/* }  */

/* .word-stat-row td[scope=row] {
  vertical-align: top;
} */

.cornerOfTable {
  z-index:3 !important;
}
.stamAshkenazCLM{
  font-size: 18px;
  font-family: "StamAshkenazCLM" !important;
  /* letter-spacing: .1em; */
}
.hebrewPaleoGlezer{
  font-family: "Hebrew-Paleo-Gezer" !important;
  text-align: justify;
  font-weight: bold;
  /* line-height: 20px; */
  font-size: 16px;
  /* padding-top: 4px; */
}
.ltrWordStat{
  font-size: 12px !important;
  direction: ltr;
  width: 670px;
  text-align: right;
}
.wordStatsTooltipLocation {
  color: #61baff !important;
  font-weight: bold;
  font-size: 14px;
}
.smallerFont{
  font-size: 10px;
}
.littleSmaller{
  font-size: 10.5px !important;
}
.red{
  color: red !important;
}
.white{
  color:white !important;
}
.ltrAlignRight {
  /* direction: ltr;
  text-align: right; */
  /* border-left: 1px white solid; */
}
/* .centerWord{
  text-align:center !important;
} */
.ag-full-width-row{
  overflow: auto !important;
}
 .word-statistic-table {
  height: 100%;
}
.redItalic{
  color:red;
  font-style: italic;
  font-size: small;
  line-height: normal;
  text-align: center !important;
  padding-top: 3px;
}
.word-stat-full-width-cell{
  background-color: white;
  position: relative;
  line-height: 1.5;
  overflow: auto;
  scrollbar-width: thin !important;
  white-space: normal;
  height: 100%;
  width: 100% !important;
  border: 2px solid grey;
  box-sizing: border-box;
  text-align: right;
  /* padding: 5px; */
  display: flex;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
/* ::-webkit-scrollbar-button{
  height: 10px;
  width: 10px;
} */
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(196, 194, 194); 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(85, 85, 85, 0.459); 
}


.objectCloudChoicesSliderDiv{
  max-width: 100px;
  display: inline-block;
  padding-top: 0px;
  margin: 13px 25px 7px;
}
.objectCloudChoices-slider-wrapper-div{
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 150px;
  margin-left: 35%;
  margin-top: 15px;
}


.objectCloudChoicesSliderDiv .rc-slider-step{
  background-color: #0056d6bd;
}
.objectCloudChoicesSliderDiv .rc-slider{
  margin-bottom: 30px;
}
.objectCloudChoicesSliderDiv .rc-slider-dot{
  border: 2px solid #0056d6bd !important;
}
.objectCloudChoicesSliderDiv .rc-slider-handle{
  border: 1px solid #0056d6bd !important;
}

.objectCloudChoicesSliderDiv .rc-slider-mark-text{
  color: #0056d6bd !important;
  font-size: 14px !important;
  text-align: center !important;
  font-weight: normal !important;
  letter-spacing: 0.02857em !important;
  -webkit-transform: translateX(50%) !important;
          transform: translateX(50%) !important;

  }
.objectCloudChoicesSliderDiv .rc-slider-mark{
  top:14px !important;
  /* left: 30px !important; */
  }
.wordCloudTitle{
  font-family: 'Times New Roman', Times, serif;
  font-size: 72px;
  font-weight: bold;
  text-align: center;
  line-height: 80px;
}
.colorSwatch{
  width: 63px !important;
  height: 35px !important;
  display: inline-block;
  font-size: 10px;
  border: solid 2px;
  padding:0 4px;
  outline:black 1px solid
}
.rc-tooltip {
  /* this may be very dangerous */
  opacity: 1 !important;
}
.tooltipChromePicker{
  display: inline-block;
}
.objectCloudSelectionSelector:hover{
  cursor:auto !important
}
.chromePicker{
  width: 150px !important;
}
.colorSwatch:hover{
  cursor:pointer !important;
  background-color: #d6d6d6 !important;
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 3px 1px -2px rgb(0 0 0 / 12%);
}
.colorSwatch{
  background-color: #e0e0e0;
}
.cancelColor{
  margin-top: 2px;
  /* float:left; */
}
.setColor{
  max-height: 19px;
  line-height: 15px;
  font-size: 12px;
  margin-left: 127px;
  margin-bottom: 2px;
  direction: rtl;
}
.animationSec input{
  display: block;
  padding-top: 0px;
  margin-top: 0px;
}
.animationSec label{
  line-height: 10px;
  font-size: 10px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.animationSec{
  display:inline-block
}
.context-menuTaamParser{
  position: absolute;
  background-color: white;
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 3px 1px -2px rgb(0 0 0 / 12%);
  /* box-shadow: rgb(0 0 0 / 16%) 4px 3px 6px 3px, rgb(0 0 0 / 23%) 1px 0px 13px 5px; */
  text-align: center !important;
  width: 260px;
  padding: 10px;
  z-index: 1310 !important;
}
/* .block select{
  display: block;
} */
.block{
  display:block
}
.searchLocation{
  max-height: 32px;
  width:95px;
  padding:2px 4px 1px 10px;
  border-radius: 2px;
  font-size: 0.875rem !important;
  color: rgba(0, 0, 0, 0.54) !important;
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 3px 1px -2px rgb(0 0 0 / 12%);
  /* 0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 3px 1px -2px rgb(0 0 0 / 12%); */
  background-color: white;
  text-align: right;
}
.MuiSelect-select-175{
  padding-right: 20px !important;
}
.searchLocationOption{
  direction: rtl;
  background-color:white;
  text-align:right;
  font-size: 0.875rem !important;
  color: rgba(0, 0, 0, 0.54) !important;
  padding:5px 10px !important;
}
.MuiMenu-paper-453{/* whole dropdown of last 20 selections*/
  max-height: 360px !important;
}
.MuiMenu-paper-769{/* whole dropdown of last 20 selections*/
  max-height: 360px !important;
}
.ag-theme-balham .ag-rtl .ag-cell{/* sets the table of the terms to show the word in the middle of the line*/
  line-height: 1.5 !important;
}

.dictaphone{
  width: 500px !important;
  height: 150px !important;
}

.dialog-resize-button {
  padding: 0 !important;
  min-width: 25px !important;
  vertical-align: -6px !important;
  border-radius: 0px !important;
  min-width: unset !important;
  width: 24px !important;
  background: #efefef !important;
}

/* .MuiList-padding-1050{
  padding:5px 0 !important;
} */





/* .word-stat-row{ */
  /* border-bottom: indigo !important; */
  /* border: 1px solid black; */
  /* border-collapse: collapse; */
/* } */
/* .header-row-word-stats{
  background-color: #d3d3d3;
  font-weight: bold !important;
  padding: 0 11px;
  min-width: fit-content;
  vertical-align: top;
} */


/* .scroll{
  line-height: normal;
  overflow-y: auto;
  max-height: fit-content;
} */

/* .centerWords{
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%,-50%);
  width: 250px;
  height: fit-content;
} */
/*
.pasukStatsTooltip p:first-of-type {
  color: #61baff !important;
  font-size: 14px;
  font-weight: bold;
}
*/


/*

.betaText2 {
  font-family: Courier New;
  font-weight: bold;
  font-style: italic;
  font-size: 36px;
  color: #61baff;
  line-height: 30px;
}

.upsideDown2 {
  font-family: Courier New;
  font-weight: bold;
  font-style: italic;
  font-size: 36px;
  color: #1e90ff;
  line-height: 30px;
  display: inline-block;
  transform-origin: 12.5px 13.22px;
  transform: rotate(180deg);
}

.betaText3 {
  font-family: Stencil;
  font-weight: bold;
  font-size: 32px;
  color: #61baff;
  line-height: 30px;
}

.upsideDown3 {
  font-family: Stencil;
  font-weight: bold;
  font-size: 32px;
  color: #1e90ff;
  line-height: 30px;
  display: inline-block;
  transform-origin: 11.2px 14.2px;
  transform: rotate(180deg);
}
*/
.admin-controls-outer-container{
    margin-left: 225px;
}

.admin-controls-page-container{
    width: 1483px;
    max-width: calc(100% - 200px);
    margin: auto;
    padding-top: 20px;
}

.admin-controls-section-paper{
    padding: 20px;
}

.admin-controls-login-paper{
    padding: 20px;
    width: 600px;
    margin: 30px auto;
    text-align: center;
}

.admin-controls-page-title{
    font-weight: bold !important;
    margin: 20px 0 !important;
}

.admin-controls-login-message{
    font-weight: bold !important;
    color: #3260fb !important;
}

#admin-search-results-expiration-spinner .p-spinner-input{
    height: 24px !important;
    width: 55px !important;
}

.admin-controls-outer-question-container{
    display: flex;
}

.admin-controls-inner-question-container{
    width: calc(100% - 88px);
    margin: 6px 0;
}

.admin-controls-current-value{
    color: grey;
    font-style: italic;
}

#user-requests-grid .ag-header, #user-requests-grid .ag-header-row{
    height: 34px !important;
}

#user-requests-grid .ag-header-cell-label .ag-header-cell-text{
    white-space: normal !important;
    line-height: 1;
    overflow-wrap: normal;
}

.admin-drawer-paper{
    position: relative !important;
    height: calc(100vh - 100px) !important;
    background-color: #e7e7e7 !important;
    width: 225px !important;
    float: left;
}

.bold-list-item-text{
    font-weight: bold !important;
}

.number-column{
    text-align: right;
}

.request-log-option{
    display: inline !important;
    margin-right: 15px !important;
}

.admin-controls-section-title{
    margin: 25px 0 15px !important;
    font-weight: bold !important;
}

.results-cache-result-table-makor{
    background-color: #d3d3d3;
    font-weight: bold !important;
    padding: 0 11px;
    width: 140px;
    vertical-align: top;
}

.results-cache-results-cell{
    background-color: white;
    position: relative;
    line-height: 1.5;
    overflow: auto;
    white-space: normal;
    height: 100%;
    width: 100% !important;
    border: 2px solid grey;
    border-style: ridge;
    box-sizing: border-box;
    text-align: right;
    padding: 5px;
    font-family: SBL Hebrew;
    direction: rtl;
}

.results-cache-memory-data{
    position: absolute;
    z-index: 1;
    right: 0;
    top: -20px;
    height: 34px;
}

.clear-from-cache-button-cell{
    font-weight: 600 !important;
    padding: 0 !important;
    background-color: #d3d3d3;
    border-radius: 7px;
    cursor: pointer;
    font-size: 15px;
    /* width: 18px !important; */
    height: 18px !important;
    margin: 3px;
    /* transition: .25s; */
}

.clear-from-cache-button-cell:hover{
    background-color: #707070;
    color: white;
}

.admin-cache-option{
    margin-right: 10px !important;
}
.about-rnd{
  background-color: black !important;

}

.about-dialog-content-root{
  flex: 1 1 auto;
  padding: 0 24px 24px;
  overflow-y: hidden !important;
  max-height: calc(100% - 68px) !important;
  cursor: default;
}

.about-dialog-paper{
    color: dodgerblue !important;
    background-color: black !important;
}

.about-dialog-title *{
    color: dodgerblue !important;
}

.about-dialog-expand-colapse-parent{
  position: absolute;
  top: -2px;
  white-space: nowrap;
}

.about-dialog-title-text-parent{
  max-width: calc(100% - 260px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}


.about-dialog-expansion-panel{
    background-color: black !important;
    border: 1px solid dodgerblue;
}

.about-dialog-expansion-panel *{
    color: #62adf5 !important;
}

.about-dialog-expansion-panel-summary *{
    color: dodgerblue !important;
}

.about-dialog-resize-button {
  padding: 0 !important;
  min-width: 25px !important;
  vertical-align: -6px !important;
  border-radius: 0px !important;
  color: dodgerblue !important;
  min-width: unset !important;
  width: 24px !important;
  background: #2e2e2e !important;
}


.about-dialog-resize-button:hover {
  background: #474747 !important;
}

.about-rnd .react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  border: 1px solid dodgerblue;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
}

.about-rnd .react-tabs__tab--selected {
  background: #fff;
  color: black;
  border-color: dodgerblue;
  border-radius: 5px 5px 0 0;
  background: #62adf5;
  font-weight: bold;
}

.about-rnd .react-tabs__tab-list {
  border-bottom: 1px solid dodgerblue;
  margin: 0 0 10px;
  padding: 0;
  color: dodgerblue;
}

.about-rnd .react-tabs__tab:focus:after {
  height: 0px;
}

.about-rnd h3{
  color: dodgerblue;
}

.about-dialog-tabs-container{
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
}

.about-dialog-tab-panels-parent{
  flex-grow: 1;
  overflow: auto;
}

/* unvisited link */
a:link {
  color: #80D0FF !important;
}

/* visited link */
a:visited {
  color: #80D0FF !important;
}

/* mouse over link */
a:hover {
  color: #80D0FF !important;
}

/* selected link */
a:active {
  color: #80D0FF !important;
}
span.a:link { color: #0000FF !important; }

.help-rnd{
  background-color: white !important;
  color: black;
}

.help-dialog-content-root{
  flex: 1 1 auto;
  padding: 0 24px 24px;
  overflow-y: hidden !important;
  max-height: calc(100% - 88px) !important;
  cursor: default;
}

.help-dialog-paper{
    color: black !important;
    background-color: white !important;
}

.help-dialog-title *{
    color: black !important;
}

.help-dialog-expand-colapse-parent{
  position: absolute;
  top: -2px;
  white-space: nowrap;
}

.help-dialog-title-text-parent{
  max-width: calc(100% - 260px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}


.help-dialog-expansion-panel{
    background-color: white !important;
    border: 1px solid black;
}

.help-dialog-expansion-panel *{
    color: black !important;
}

.help-dialog-expansion-panel-summary *{
    color: black !important;
}

.help-dialog-close-button{
  background: dodgerblue !important;
  min-width: unset !important;
  width: 24px;
  padding: 0 !important;
  position: absolute !important;
  color: white !important;
}


.help-dialog-resize-button {
  padding: 0 !important;
  min-width: 25px !important;
  vertical-align: -6px !important;
  border-radius: 0px !important;
  color: dodgerblue !important;
  min-width: unset !important;
  width: 24px !important;
  background: #efefef !important;
}

.help-dialog-resize-button:hover {
  background: #c4c4c4 !important;
}

.help-rnd .react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  border: 1px solid dodgerblue;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
}

.help-rnd .react-tabs__tab--selected {
  background: #fff;
  color: white;
  border-color: dodgerblue;
  border-radius: 5px 5px 0 0;
  background: dodgerblue;
  font-weight: bold;
}

.help-rnd .react-tabs__tab-list {
  border-bottom: 1px solid dodgerblue;
  margin: 0 0 10px;
  padding: 0;
  color: dodgerblue;
}

.help-rnd .react-tabs__tab:focus:after {
  height: 0px;
}

.help-rnd h3{
  color: black;
}

/* react-dropdown-tree-select overrides - main definitions are in it's own styles.css w/i node_modules*/
.node.disabled>*{
  font-weight: bold !important;
  cursor:default !important;
}




/* fallback, until https://github.com/CompuIves/codesandbox-client/issues/174 is resolved */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v36/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
    format("woff2");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

/* mdl-demo is a custom classname to increases the specificity of our styles. It can be anything. 
 * The idea is that it is easy to extend/override builtin styles with very little effort.
 */

.mdl-demo {
  position: absolute;
  top: 0;
  cursor: auto;
  cursor: initial;
}

.mdl-demo .checkbox-item{
  display: none;
}

.mdl-demo.en {
  left: 68px;
}

.mdl-demo.he {
  right: 68px;
}

.mdl-demo .node label:hover{
  background: lightgray;
  -webkit-transition: .25s;
  transition: .25s;
}

.mdl-demo .tag-item:not(:last-child){
  display: none;
}

.mdl-demo .dropdown-trigger > span:after {
  font-size: 12px;
  color: #555;
}

.mdl-demo .toggle {
  font: normal normal normal 18px/1 "Material Icons";
  color: #555;
  white-space: pre;
  margin-right: 4px;
}

.mdl-demo .toggle.collapsed::after {
  cursor: pointer;
  content: "\E5CF";
  vertical-align: middle;
}

.mdl-demo .toggle.expanded::after {
  cursor: pointer;
  content: "\E5CE";
  vertical-align: middle;
}

/* checkbox styles */
.mdl-demo .checkbox-item {
  position: relative;
  width: 1rem;
  height: 1rem;
  margin-right: 0.75rem;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  vertical-align: middle;
}

.mdl-demo .checkbox-item:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  border: 2px solid #aaa;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.mdl-demo .checkbox-item:checked:before {
  height: 50%;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border-top-style: none;
  border-right-style: none;
  border-color: #2196f3;
}
.help-dialog-tabs-container{
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
}

.help-dialog-tab-panels-parent{
  flex-grow: 1;
  overflow: auto;
}




 /* Font Definitions from User Manual MS-Word conversion */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:"Franklin Gothic Heavy";
	panose-1:2 11 9 3 2 1 2 2 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin:0in;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoHeader, li.MsoHeader, div.MsoHeader
	{mso-style-link:"Header Char";
	margin:0in;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoFooter, li.MsoFooter, div.MsoFooter
	{mso-style-link:"Footer Char";
	margin:0in;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
/* this one throws other stuff off so don't use
p
	{margin-right:0in;
	margin-left:0in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
*/
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
span.HeaderChar
	{mso-style-name:"Header Char";
	mso-style-link:Header;}
span.FooterChar
	{mso-style-name:"Footer Char";
	mso-style-link:Footer;}
 /* Page Definitions */
 @page WordSection1
	{size:8.5in 11.0in;
	margin:1.0in 1.0in 1.0in 1.0in;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0in;}
ul
	{margin-bottom:0in;}
/* MJE - moved style.css from C:\Work\TaryagAnalytics\projects-Git\code\BaseHaSefer-React\basehasefer\node_modules\react-super-treeview\dist to here */
/* MJE - modified from original (indicated where changed w/comments) in order to provide RTL support */

.super-treeview {
  font-size: 100%;}
  .super-treeview > div > .super-treeview-no-children-transition {
    -webkit-transition-property: all;
    transition-property: all; }
  .super-treeview > div > .super-treeview-no-children-transition-enter {
    opacity: 0;
    height: 0;
    overflow: hidden; }
    .super-treeview > div > .super-treeview-no-children-transition-enter.super-treeview-no-children-transition-enter-active {
      opacity: 1;
      height: 0; }
  .super-treeview > div > .super-treeview-no-children {
    overflow: hidden; }
    .super-treeview > div > .super-treeview-no-children > .super-treeview-no-children-content {
      margin: 2px 0; } /* 2px 0 */
  .super-treeview > div > .super-treeview-node {
    overflow: hidden; }
    .super-treeview > div > .super-treeview-node > .super-treeview-children-container {
      margin-right: 6px;  /* left 8px */
      padding-right: 15px;  /* left 20px */
      border-right: 1px solid #b87333; } /* left #000 */
      .super-treeview > div > .super-treeview-node > .super-treeview-children-container > .super-treeview-loading {
        padding: 0 0;  /* 0 3px */
        margin: 2px 0; } /* 2px 0 */
    .super-treeview > div > .super-treeview-node > .super-treeview-node-content {
      padding: 0 3px;  /* 0 3px */
      margin: 0 0; } /* 2px 0 */
      .super-treeview > div > .super-treeview-node > .super-treeview-node-content > label {
        vertical-align: top;
        text-overflow: ellipsis;
        width: calc(100% - 35px); /* 55px */
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
        margin: 0;
        font-weight: normal;
        font-size: 100%; }
      .super-treeview > div > .super-treeview-node > .super-treeview-node-content > input[type=checkbox] {
        float: right; /* left; */
        margin: 0 0 0 4px; /* 4px 5px 0 0 - indicates top, right, bottom, left*/
        line-height: normal; }
      .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .super-treeview-triangle-btn {
        float: right; /* left; */
        margin-left: 4px;  /* 5px right; */
        cursor: pointer; }
      .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .super-treeview-triangle-btn-right {
        width: 8px;
        height: 10px;
        margin-top: 0; /* 2px; */
        margin-right: 0; /* 2px left; */
        border-top: 5px solid transparent;
        border-right: 6px solid #b87333;  /* left #000 */
        border-bottom: 5px solid transparent; }
      .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .super-treeview-triangle-btn-down {
        width: 10px;
        height: 8px;
        margin-top: 5px; /* 5px; */
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 6px solid #b87333; }  /* #000 */
      .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .delete-btn {
        float: left; /* right; */
        cursor: pointer; }
  .super-treeview > div > .super-treeview-node-transition {
    -webkit-transition-property: all;
    transition-property: all; }
  .super-treeview > div > .super-treeview-node-transition-enter {
    opacity: 0; }
    .super-treeview > div > .super-treeview-node-transition-enter.super-treeview-node-transition-enter-active {
      opacity: 1; }
  .super-treeview > div > .super-treeview-node-transition-exit {
    opacity: 1; }
    .super-treeview > div > .super-treeview-node-transition-exit.super-treeview-node-transition-exit-active {
      opacity: 0;
      background-color: red; }

.exactnessSliderDiv .rc-slider {
    width: 100px;
    padding-top: 0px;
    margin-top: 30px;
    margin-right: 30px;
    margin-bottom: 30px;
    }

.parts-of-speech-dropdown-parent .rc-slider-mark-text{
    font-weight: bold !important;
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif" !important;
    font-size: 14px !important;
    -webkit-transform: translateX(50%) !important;
            transform: translateX(50%) !important;
    }
.simpleFuzzyPickerDiv .rc-slider-mark-text{
     font-family: "Roboto", "Helvetica", "Arial", "sans-serif" !important;
    font-size: 14px !important;
    -webkit-transform: translateX(50%) !important;
            transform: translateX(50%) !important;
}

.simpleFuzzyPickerDiv .exactnessSliderDiv .rc-slider-step{
    background-color: #b87333;
}
.simpleFuzzyPickerDiv .exactnessSliderDiv .rc-slider{
    margin-bottom: 30px;
}
.simpleFuzzyPickerDiv .exactnessSliderDiv .rc-slider-dot{
    border: 2px solid #b87333 !important;
}
.simpleFuzzyPickerDiv .exactnessSliderDiv .rc-slider-handle{
    border: 1px solid #b87333 !important;
}

.simpleFuzzyPickerDiv .exactnessSliderDiv .rc-slider-mark-text{
    color: #b87333 !important;
    font-size: 12px !important;
    letter-spacing: 0.02857em !important;
    }

.exactnessLevel-slider-wrapper-div {
    display: grid !important;
    grid-columns-template: 1fr 3fr;
}

.parts-of-speech-dropdown-parent .exactnessSliderDiv .rc-slider {
    width: 165px;
    margin-left: 75px;
    margin-top: 10px;
    margin-bottom: 30px;
    }
.rc-slider-disabled {
    background-color: transparent !important;
    }
.rc-slider-disabled .rc-slider-step{
    background-color: #e9e9e9 !important;
}

.simpleFuzzyPickerDiv .rc-slider-tooltip-inner{
    color: #b87333 !important;
    }

{/*formatting the positioning of the exactnesslevel slider and checkbox*/}
.exactnessLevel-slider-wrapper-div{
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 150px;
    margin-left: 35%;
    margin-top: 15px;
}

.advExactnessLevel-checkbox{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 5px;
}



   .superTreeViewCS .super-treeview * {
    box-sizing: border-box;
    -webkit-box-sizing: border-box; }
  
  .superTreeViewCS .super-treeview {
    font-size: 100%; }
    .superTreeViewCS .super-treeview > div > .super-treeview-no-children-transition {
      -webkit-transition-property: all;
      transition-property: all; }
    .superTreeViewCS .super-treeview > div > .super-treeview-no-children-transition-enter {
      opacity: 0;
      height: 0;
      overflow: hidden; }
      .superTreeViewCS .super-treeview > div > .super-treeview-no-children-transition-enter.super-treeview-no-children-transition-enter-active {
        opacity: 1;
        height: 0; }
    .superTreeViewCS .super-treeview > div > .super-treeview-no-children {
      overflow: hidden; }
      .superTreeViewCS .super-treeview > div > .super-treeview-no-children > .super-treeview-no-children-content {
        margin: 2px 0; }
    .superTreeViewCS .super-treeview > div > .super-treeview-node {
      overflow: hidden; }
      .superTreeViewCS .super-treeview > div > .super-treeview-node > .super-treeview-children-container {
        margin-left: 12px;
        padding-left: 15px;
        border-left: 1px solid #000; 
        margin-right: 0px;
        padding-right: 0px;
        border-right: none;}
        .superTreeViewCS .super-treeview > div > .super-treeview-node > .super-treeview-children-container > .super-treeview-loading {
          padding: 0 3px;
          margin: 2px 0; }
      .superTreeViewCS .super-treeview > div > .super-treeview-node > .super-treeview-node-content {
        white-space: nowrap;
        padding: 0 3px;
        margin: 2px 0; }
        .superTreeViewCS .super-treeview > div > .super-treeview-node > .super-treeview-node-content > label {
          vertical-align: top;
          text-overflow:inherit;
          width: calc(100% - 25px);
          overflow: auto;
          white-space: nowrap;
          display: inline-block;
          margin: 0;
          font-weight: normal;
          font-size: 100%; }
        .superTreeViewCS .super-treeview > div > .super-treeview-node > .super-treeview-node-content > input[type=checkbox] {
          float: left;
          margin: 4px 5px 0 0;
          line-height: normal; }
        .superTreeViewCS .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .super-treeview-triangle-btn {
          float: left;
          margin-right: 5px;
          cursor: pointer; }
        .superTreeViewCS .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .super-treeview-triangle-btn-none {
          cursor: none; }
        .superTreeViewCS .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .super-treeview-triangle-btn-right {
          width: 8px;
          height: 10px;
          margin-top: 2px;
          margin-left: 2px;
          border-top: 5px solid transparent;
          border-left: 6px solid #000;
          border-right: none;
          border-bottom: 5px solid transparent; }
        .superTreeViewCS .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .super-treeview-triangle-btn-down {
          width: 10px;
          height: 8px;
          margin-top: 5px;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 6px solid #000; }
        .superTreeViewCS .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .delete-btn {
          float: right;
          cursor: pointer; }
    .superTreeViewCS .super-treeview > div > .super-treeview-node-transition {
      -webkit-transition-property: all;
      transition-property: all; }
    .superTreeViewCS .super-treeview > div > .super-treeview-node-transition-enter {
      opacity: 0; }
      .superTreeViewCS .super-treeview > div > .super-treeview-node-transition-enter.super-treeview-node-transition-enter-active {
        opacity: 1; }
    .superTreeViewCS .super-treeview > div > .super-treeview-node-transition-exit {
      opacity: 1; }
      .superTreeViewCS .super-treeview > div > .super-treeview-node-transition-exit.super-treeview-node-transition-exit-active {
        opacity: 0;
        background-color: red; }
  

/* Hebrew is set to scroll indevidule lines */
.superTreeViewCSHeb .super-treeview * {
          box-sizing: border-box;
          -webkit-box-sizing: border-box; }
        
  .superTreeViewCSHeb .super-treeview {
          font-size: 100%; }
  .superTreeViewCSHeb .super-treeview > div > .super-treeview-no-children-transition {
            -webkit-transition-property: all;
            transition-property: all; }
  .superTreeViewCSHeb .super-treeview > div > .super-treeview-no-children-transition-enter {
            opacity: 0;
            height: 0;
            overflow: hidden; }
    .superTreeViewCSHeb .super-treeview > div > .super-treeview-no-children-transition-enter.super-treeview-no-children-transition-enter-active {
              opacity: 1;
              height: 0; }
  .superTreeViewCSHeb .super-treeview > div > .super-treeview-no-children {
            overflow: hidden; }
    .superTreeViewCSHeb .super-treeview > div > .super-treeview-no-children > .super-treeview-no-children-content {
              margin: 2px 0; }
  .superTreeViewCSHeb .super-treeview > div > .super-treeview-node {
            overflow: hidden; }
    .superTreeViewCSHeb .super-treeview > div > .super-treeview-node > .super-treeview-children-container {
              margin-right: 12px;
              padding-right: 15px;
              border-left: none; 
              border-right: 1px solid #000;}
              .superTreeViewCSHeb .super-treeview > div > .super-treeview-node > .super-treeview-children-container > .super-treeview-loading {
                padding: 0 3px;
                margin: 2px 0; }
            .superTreeViewCSHeb .super-treeview > div > .super-treeview-node > .super-treeview-node-content {
              white-space: nowrap;
              padding: 0 3px;
              margin: 2px 0; }
              .superTreeViewCSHeb .super-treeview > div > .super-treeview-node > .super-treeview-node-content > label {
                vertical-align: top;
                text-overflow:inherit;
                width: calc(100% - 25px);
                overflow: auto;
                white-space: nowrap;
                display: inline-block;
                margin: 0;
                font-weight: normal;
                font-size: 100%; }
              .superTreeViewCSHeb .super-treeview > div > .super-treeview-node > .super-treeview-node-content > input[type=checkbox] {
                float: right;
                margin: 4px 5px 0 0;
                line-height: normal; }
              .superTreeViewCSHeb .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .super-treeview-triangle-btn {
                float: right;
                margin-right: 5px;
                cursor: pointer; }
              .superTreeViewCSHeb .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .super-treeview-triangle-btn-none {
                cursor: none; }
              .superTreeViewCSHeb .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .super-treeview-triangle-btn-right {
                width: 8px;
                height: 10px;
                /* margin-top: 2px;
                margin-left: 2px; */
                margin-top: 0;
                margin-right: 2px;
                border-top: 5px solid transparent;
                border-left: none;
                border-right:6px solid #000 ;
                border-bottom: 5px solid transparent; }
              .superTreeViewCSHeb .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .super-treeview-triangle-btn-down {
                width: 10px;
                height: 8px;
                margin-top: 5px;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 6px solid #000; }
              .superTreeViewCSHeb .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .delete-btn {
                float: left;
                cursor: pointer; }
          .superTreeViewCSHeb .super-treeview > div > .super-treeview-node-transition {
            -webkit-transition-property: all;
            transition-property: all; }
          .superTreeViewCSHeb .super-treeview > div > .super-treeview-node-transition-enter {
            opacity: 0; }
            .superTreeViewCSHeb .super-treeview > div > .super-treeview-node-transition-enter.super-treeview-node-transition-enter-active {
              opacity: 1; }
          .superTreeViewCSHeb .super-treeview > div > .super-treeview-node-transition-exit {
            opacity: 1; }
            .superTreeViewCSHeb .super-treeview > div > .super-treeview-node-transition-exit.super-treeview-node-transition-exit-active {
              opacity: 0;
              background-color: red; }
.leftBar-search-criteria .super-treeview > div > .super-treeview-node > .super-treeview-node-content > label{
  overflow-x: clip !important;
  width: inherit;
}
/* props marked important to override rc-slider styles in its index.css*/
.rc-slider-vertical .rc-slider-rail {
  height: 140% !important;
  margin-left: -1px !important;
  margin-top: -3px !important;
  width: 6px !important;
  background-color: #61baff !important;
}

.rc-slider-handle {
  position: absolute;
  margin-left: -5px !important;
  width: 14px !important;
  height: 14px !important;
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: grab;
  border-radius: 50%;
  border: solid 2px #61baff !important;
}
/*
.rc-slider-handle {
  position: absolute;
  margin-left: -5px !important;
  width: 14px !important;
  height: 10px !important;
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: grab;
  border-radius: 50%;
  border: solid 2px #61baff !important;
}

.rc-slider-dot {
  bottom: -9999px !important;
  margin-left: 0px !important;
  width: 0px !important;
  height: 0px !important;
}
*/

.rc-slider-mark-text {
  text-align: left !important;
  text-width: 20px !important;
  color: gray !important;
  font-size: 13px !important;
}
.rc-slider-mark-text-active {
  text-width: 20px !important;
  text-align: left !important;
  font-weight: bold !important;
  color: black !important;
  font-size: 16px !important;
}

/*make sure toggle buttons are labeled  in lowercase*/
.MuiToggleButton-root-167{
text-transform: capitalize !important;
}
/*
input {
  width: 100%;
  height: 100px;
  padding: 20px;
  font-size: 20px;
  border: none;
  box-sizing: border-box;
}
*/

.simple-keyboard {
  max-width: 850px;
}

/*
  Theme: myTheme2
*/
.simple-keyboard.myTheme2 {
  padding: 0;
  border-radius: 5px;
  margin: 3px;
  width: calc(100% - 20px);
}

.simple-keyboard.myTheme2 .hg-row {
  margin: 0;
}

.simple-keyboard.myTheme2 .hg-row:not(:last-child) {
  margin-bottom: 0;
}

.simple-keyboard.myTheme2 .hg-row .hg-button {
  background: #4c7dd0; /* background: #9cbffb; */
  color: white;
  margin: 0;
  border-radius: 0;
  border: none;
  padding: 5px; /* 15px */
  box-sizing: border-box;
  height: 37px; /* 50px */
}

.simple-keyboard.myTheme2 .hg-row .hg-button:not(:last-child) {
  margin-right: 0;
}

.simple-keyboard.myTheme2 .hg-row .hg-button span {
  -webkit-transition: all 0.05s ease-in-out;
  transition: all 0.05s ease-in-out;
}

/*

.simple-keyboard.myTheme2 .hg-row .hg-button:not(:active) {
  animation: myTheme2Anim 2s infinite alternate;
}
*/

.simple-keyboard.myTheme2 .hg-row .hg-button.myTheme2b {
  -webkit-animation: none;
          animation: none;
  background: rgba(255, 60, 60, 0.86);
  color: white;
}

.simple-keyboard.myTheme2 .hg-row .hg-button:active {
  color: white;
  background: rgba(0, 0, 0, 0.8);
}
/*
.simple-keyboard.myTheme2 .hg-row:nth-child(1) .hg-button {
  animation-delay: 0.5s;
}
.simple-keyboard.myTheme2 .hg-row:nth-child(2) .hg-button {
  animation-delay: 1s;
}
.simple-keyboard.myTheme2 .hg-row:nth-child(3) .hg-button {
  animation-delay: 1.5s;
}
.simple-keyboard.myTheme2 .hg-row:nth-child(4) .hg-button {
  animation-delay: 2s;
}
.simple-keyboard.myTheme2 .hg-row:nth-child(5) .hg-button {
  animation-delay: 2.5s;
}
*/

.regular-character-keyboard .simple-keyboard.myTheme2 .hg-button span {
  font-size: 18px;
  display: inline;
  text-align: center;
}

.regular-character-keyboard .simple-keyboard.myTheme2 .hg-button:hover span {
  font-size: 25px;
  display: inline;
  text-align: center;
}


.taam-nekuda-keyboard .simple-keyboard.myTheme2 .hg-button span {
  display: inline;
  text-align: center;
  font-size: 22px;
  line-height: 0px;
}

.taam-nekuda-keyboard .simple-keyboard.myTheme2 .hg-button:hover span {
  font-size: 30px;
  display: inline;
  text-align: center;
}

@-webkit-keyframes myTheme2Anim {
  from {
    background: #9cbffb;
  }
  to {
    background: #4c7dd0;
  }
}

@keyframes myTheme2Anim {
  from {
    background: #9cbffb;
  }
  to {
    background: #4c7dd0;
  }
}

/* the following from https://codesandbox.io/s/github/hodgef/react-simple-keyboard-demos/tree/full-keyboard/
input {
  width: 100%;
  height: 100px;
  padding: 20px;
  font-size: 20px;
  border: none;
  box-sizing: border-box;
}
*/

/* the following seems to be based on .simple-keyboard.hg-theme-default .hg-button  */
.keyboardContainer {
  display: flex;
  background-color: rgba(0, 0, 0, 0.1);
  justify-content: center;
  width: 525px;
  margin: 0 auto;
  border-radius: 5px;
  direction: rtl;
}

.simple-keyboard.hg-theme-default {
  display: inline-block;
}

.simple-keyboard-letters.simple-keyboard {
/*
  width: 640px;
  min-width: 640px;
*/
  width: 240px;
  min-width: 240px;
  background: none;
}

.simple-keyboard-letters.simple-keyboard .hg-row:first-child {
  /* margin-bottom: 10px; */
}

.simple-keyboard-nekudos.simple-keyboard {
  width: 120px;
  min-width: 120px;
  background: none;
}

.simple-keyboard-taamim.simple-keyboard {
  width: 200px;
  min-width: 200px;
  background: none;
}

.simple-keyboard-sifreiEmet.simple-keyboard {
  width: 200px;
  min-width: 200px;
  background: none;
}

.simple-keyboard-special.simple-keyboard {
  width: 140px;
  min-width: 140px;
  background: none;
}


.simple-keyboard-arrows.simple-keyboard {
  align-self: flex-end;
  background: none;
}

.simple-keyboard .hg-button.selectedButton {
  background: rgba(5, 25, 70, 0.53);
  color: white;
}

.simple-keyboard .hg-button.emptySpace {
  pointer-events: none;
  background: none;
  border: none;
  box-shadow: none;
}

.simple-keyboard-arrows .hg-row {
  justify-content: center;
}

.simple-keyboard-arrows .hg-button {
  width: 50px;
  flex-grow: 0;
  justify-content: center;
  display: flex;
  align-items: center;
}

.controlArrows {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: column;
}

.simple-keyboard-control.simple-keyboard {
  background: none;
}

.simple-keyboard-control.simple-keyboard .hg-row:first-child {
  margin-bottom: 10px;
}

.simple-keyboard-control .hg-button {
  width: 50px;
  flex-grow: 0;
  justify-content: center;
  display: flex;
  align-items: center;
}

.numPad {
  display: flex;
  align-items: flex-end;
}

.simple-keyboard-numpad.simple-keyboard {
  background: none;
}

.simple-keyboard-numpad.simple-keyboard {
  width: 160px;
}

.simple-keyboard-numpad.simple-keyboard .hg-button {
  width: 50px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.simple-keyboard-numpadEnd.simple-keyboard {
  width: 50px;
  background: none;
  margin: 0;
  padding: 5px 5px 5px 0;
}

.simple-keyboard-numpadEnd.simple-keyboard .hg-button {
  align-items: center;
  justify-content: center;
  display: flex;
}

.simple-keyboard-numpadEnd .hg-button.hg-standardBtn.hg-button-plus {
  height: 85px;
}

.simple-keyboard-numpadEnd.simple-keyboard .hg-button.hg-button-enter {
  height: 85px;
}

.simple-keyboard.hg-theme-default .hg-button.hg-selectedButton {
  background: rgba(5, 25, 70, 0.53);
  color: white;
}

.hg-button.hg-functionBtn.hg-button-space {
  width: 350px;
}


 .treeTaamParser {
  white-space: nowrap;
  min-width: 800px;
  min-height: 500px;
  height: 60vh;
  text-align: center !important;
  align-items: center !important;
  align-content: center !important;
  direction: ltr !important;

}

 .treeTaamParser ul {
  padding-top: 20px;
  padding-left: 0px;
  position: relative;
  transition: all 0s;
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  white-space: nowrap;
}

 .treeTaamParser li {
  display: inline-block;
  text-align: center;
  list-style-type: none;
  position: relative;
  vertical-align: top;
  padding: 20px 5px 0 5px;
  transition: all 0s;
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
}

/*We will use ::before and ::after to draw the connectors*/
 .treeTaamParser li::before,
 .treeTaamParser li::after {
  content: '';
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 1px solid #ccc;
  width: 50%;
  height: 20px;
}

 .treeTaamParser li::after {
  right: auto;
  left: 50%;
  border-left: 1px solid #ccc;
}

/*We need to remove left-right connectors from elements without any siblings*/
 .treeTaamParser li:only-child::after,
 .treeTaamParser li:only-child::before {
  display: none;
}

/*Remove space from the top of single children*/
 .treeTaamParser li:only-child {
  padding-top: 0;
}

/*Remove left connector from first child and right connector from last child*/
 .treeTaamParser li:first-child::before,
 .treeTaamParser li:last-child::after {
  border: 0 none;
}

/*Adding back the vertical connector to the last nodes*/
 .treeTaamParser li:last-child::before {
  border-right: 1px solid #ccc;
  border-radius: 0 5px 0 0;
  -webkit-border-radius: 0 5px 0 0;
  -moz-border-radius: 0 5px 0 0;
}

 .treeTaamParser li:first-child::after {
  border-radius: 5px 0 0 0;
  -webkit-border-radius: 5px 0 0 0;
  -moz-border-radius: 5px 0 0 0;
}

/*Time to add downward connectors from parents*/
 .treeTaamParser ul ul::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 1px solid #ccc;
  width: 0;
  height: 20px;
}

 .treeTaamParser li div {
  border: 1px solid #ccc;
  padding: 5px 10px;
  text-decoration: none;
  color: #666;
  font-family: arial, verdana, tahoma;
  font-size: 11px;
  display: inline-block;

  min-height: 30px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  transition: all 0s;
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
}

 .treeTaamParser li div .node {
  display: inline-block;
  color: black;
  padding: 10px;
  border-radius: 5px;
  font-size: 18px;
  direction: rtl;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}

 .קיסר{
  background-color: #C9BDFF;
}

 .מלך{
  background-color: #BECEF0;
}

 .משנה{
  background-color: #B3DEE2;
}

 .שליש{
  background-color: #9CFFC4;
}

/*
.משרת{
  background-color: #9CFFC4;
}
*/

 .מפסיק-נוסף{
  background-color: #efd0f5;
}

 .treeTaamParser li div .spacer {
  background-color: lightblue;
  display: inline-block;
  width: 10px;
}

 .treeTaamParser li div.leaf {
  cursor: default;
}

 .treeTaamParser li div.non-leaf {
  cursor: pointer;
  padding-right: 0px;
}

/*Time for some hover effects*/
/*We will apply the hover effect to the lineage of the element also*/
 .treeTaamParser li div.non-leaf:hover,
 .treeTaamParser li div.non-leaf:hover + ul li div {
  background: #c8e4f8;
  color: #000;
  border: 1px solid #94a0b4;
}

/*Connector styles on hover*/
 .treeTaamParser li div.non-leaf:hover + ul li::after,
 .treeTaamParser li div.non-leaf:hover + ul li::before,
 .treeTaamParser li div.non-leaf:hover + ul::before,
 .treeTaamParser li div.non-leaf:hover + ul ul::before {
  border-color: #94a0b4;
}

 .open-close-branch-icon{
    height: 17px !important;
    width: 17px !important;
    margin: 3px;
    vertical-align: center;
    color: #94a0b4;
}

 .taam-parser-title{
    display: inline;
    margin: 0 80px;
    /* 24%; */
}

/* .react-contexify__item__content.context-menu-checkbox{
  text-align: center;
} */

/* Legend */
#legend { 
    /* MOVED THIS TO YuchsinTree.css under .yuchsin-viewer-legend */
    /* bottom: 0; 
    position: fixed;
    margin: 15px;
    background-color: #222; */
}

#legend-button, #legend-content-dark, #legend-content-light, #surnames-button, .surnames-content, .surnames-heading {
    margin: 0px auto;
    padding-top: 5px;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 5px;
}

#legend-button, #surnames-button {
    color: #888;
}

#legend-button:hover, #surnames-button:hover {
    background-color: #2a2a2a;
    cursor: pointer;
}

#legend-content-dark {
    width: 270px;
    max-height: 455px;
    /* color: #ccc; */
    overflow-y: auto;
}

#legend-content-light {
    width: 270px;
    max-height: 455px;
    /* color: #ccc; */
    overflow-y: auto;
}

/*line spacing is 1px*/
#legend-content-dark p {
    margin: 1px 0px;
}

#legend-content-light p {
    margin: 1px 0px;
}


#legend-content-dark a {
  color: #80D0FF !important;
}

#legend-content-light a {
  color: #0050FF !important;
}

.legend-line {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.legend-line img {
    height: 100%;
    margin-right: 4px;
}

#controls #back-button, #controls #legend, #controls #surnames {
    z-index: 2;
}
.basic-3d{
  background-color: black;
}

span .yuchsin-tree-mini-source {
  display: inline-block;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: sub;
}

.yuchsin-tree-source-selector-button {
  margin: 4px !important;
  height: 18px !important;
  vertical-align: bottom;
  padding: 4px !important;
  min-width: 0 !important;
}

.yuchsin-name-dropdown {
  z-index: 10 !important;
}

/*
  for more info on the code below see https://github.com/dowjones/react-dropdown-tree-select/blob/develop/src/index.css
  and https://github.com/dowjones/react-dropdown-tree-select#faq
*/
.yuchsin-name-dropdown-container{
  z-index: 10 !important;
  display: inline-block;
  justify-content: center;
  vertical-align: top;
  width: 225px !important;
  font-size: 14px;
  cursor: pointer;
}

.yuchsin-name-dropdown-container .dropdown-trigger {
  z-index: 10 !important;
  display: flex !important;
  justify-content: center !important;
  width: 215px !important;
  padding: 0px 3px !important;
  background-color: white !important;
}

.yuchsin-name-dropdown-container .search {
  width: 175px !important;
}

.yuchsin-name-dropdown-container .dropdown-content{
  z-index: 10 !important;
  height: 300px !important;
  width: 200px !important;
  border-right: rgba(0, 0, 0, 0.25) 1px solid !important;
  border-left: rgba(0, 0, 0, 0.25) 1px solid !important;
  border-bottom: rgba(0, 0, 0, 0.25) 1px solid !important;
  overflow: auto;
}

.yuchsin-name-dropdown-container .node{
  height: 27px;
  text-align: right;
  overflow: hidden;
}

.yuchsin-name-dropdown-container .node:hover{
  background-color: lightgrey;
}

.yuchsin-name-dropdown-container .node>label {
  min-width: 100%;
}

.yuchsin-focusedNode {
  margin: 3px;
  width: 220px !important;
  padding: 0px 3px !important;
  font-size: 14px;
  text-align: right;
  text-overflow: ellipsis;
}

.yuchsin-pathText-container {
  padding: 10px 7px !important;
}

.yuchsin-pathText {
  height: 310px !important;
  width: 100% !important;
  padding: 3px 7px !important;
  overflow: scroll;
  font-size: 14px;
  text-align: right;
-webkit-user-select:text !important;
-moz-user-select:text !important;
-ms-user-select:text !important;
user-select:text !important;
}

.yuchsin-viewer-legend {
  z-index: 1;
  position: absolute;
  margin: 15px;
  /*  background-color: #222; */
  font-size: 14px;
}

.yuchsin-tree-hover-label-dark{
  width: 250px;
  background-color: #222222;
  padding: 10px;
}

.yuchsin-tree-hover-MAN-dark{
  color: #a0a0ff;
}

.yuchsin-tree-hover-WOMAN-dark{
  color: #ffa0a0;
}

.yuchsin-tree-hover-FAMILY-dark{
  color: #a0ffa0;
}

.yuchsin-tree-hover-label-light{
  width: 250px;
  background-color: #dddddd;
  color: black;
  padding: 10px;
}

.yuchsin-tree-hover-MAN-light{
  color: #2020c0;
}

.yuchsin-tree-hover-WOMAN-light{
  color: #c02020;
}

.yuchsin-tree-hover-FAMILY-light{
  color: #20c020;
}

.yuchsin-tree-pasuk-text-dark{
  color: #c8c8c8;
}

.yuchsin-tree-pasuk-text-light{
  color: #808080;
}

.yuchsin-tree-controls{
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 15px;
  /* background-color: #222 !important; */
  color: #888;
  font-size: 14px;
}

#settings-button {
    margin: 0px auto;
    padding-top: 5px;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 5px;
}

#settings-content p {
    margin: 5px 15px;
    /* color: #ccc; */
}

.yuchsin-tree-header{
  position: absolute;
    top: 35px;  /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-family: Shlomo Stam;
    font-size: 24px;
    background-color: transparent !important;
}

.yuchsin-tree-loading{
  position: absolute;
    top: 300px;  /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    color: #cccccc;
    font-size: 48px;
  background-color: transparent !important;
}

.yuchsin-tree-highlighting{
  position: absolute;
    top: 300px;  /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    /* color: #cccccc; */
    font-size: 48px;
    z-index: 1234;
  /* background-color: transparent !important; */
}

.yuchsin-tree-header-sm{
    font-family: TimesNewRoman;
    font-size: 20px;
}

.yuchsin-tree-controls label{
  margin: 0;
  /* color: #ccc; */
}

.yuchsin-tree-search {
  height: 24px;
}

.yuchsin-person2-selector{
  display: flex;
  justify-content: center;
  height: 30px;
  width: 380px;
}

.yuchsin-findPath-bottom {
  position: relative;
  direction: ltr !important;
 }

.yuchsin-findPath-bottom-caption {
  display: inline-block;
  width: 280px;
  text-align: center;
  align: center;
  color: blue;
  font-size: 18px;
  font-weight: bold;
  font-style: italic;
  padding: 0px 0px 7px 110px;
}

.yuchsin-findPath-bottom-button {
  display: inline-block;
  float: right;
  padding: 0px 7px 7px 7px;
}

.generations-selector-2D-popover [class*="MuiPopover-paper"]{
  padding: 5px !important;
  width: 415px;
}

.generations-slider {
  display: inline-block !important;
  width: 160px !important;
}

.generations-buttons {
  direction: ltr !important;
  padding: 0px 0px 0px 115px;
}
.superTreeviewLexicon .super-treeview {
    font-size: 100%;}
    .superTreeviewLexicon .super-treeview > div > .super-treeview-no-children-transition {
      -webkit-transition-property: all;
      transition-property: all; }
      .superTreeviewLexicon .super-treeview > div > .super-treeview-no-children-transition-enter {
      opacity: 0;
      height: 0;
      overflow: hidden; }
    .superTreeviewLexicon .super-treeview > div > .super-treeview-no-children-transition-enter.super-treeview-no-children-transition-enter-active {
        opacity: 1;
        height: 0; }
    .superTreeviewLexicon .super-treeview > div > .super-treeview-no-children {
      overflow: hidden; }
    .super-treeview > div > .super-treeview-no-children > .super-treeview-no-children-content {
        margin: 2px 0; 
        text-align: center;} /* 2px 0 */
    .superTreeviewLexicon .super-treeview > div > .super-treeview-node {
      overflow: hidden; }
    .superTreeviewLexicon .super-treeview > div > .super-treeview-node > .super-treeview-children-container {
        font-weight: normal !important;
        margin-right: 6px;  /* left 8px */
        padding-right: 15px;  /* left 20px */
        border-right: 1px solid #000000; } /* left #000 */
    .superTreeviewLexicon .super-treeview > div > .super-treeview-node > .super-treeview-children-container > .super-treeview-loading {
          font-weight: normal !important;
          padding: 0 0;  /* 0 3px */
          margin: 2px 0; } /* 2px 0 */
    .superTreeviewLexicon .super-treeview > div > .super-treeview-node > .super-treeview-node-content {
        padding: 0px 3px;  /* 0 3px */
        margin: 2px 0;
        line-height: normal;} /* 2px 0 */
    .superTreeviewLexicon .super-treeview > div > .super-treeview-node > .super-treeview-node-content:hover {
            background-color: lightblue;
    }
    .superTreeviewLexicon .super-treeview > div > .super-treeview-node > .super-treeview-node-content > label {
          vertical-align: top;
          text-overflow: ellipsis;
          width:inherit;/*set the width here so that the title doesn't show as [object:object] when hover next to the words*/
          /* width: calc(100% - 65px); 55px */
          overflow: hidden;
          white-space: nowrap;
          display: inline-block;
          margin: 0;
          font-weight: inherit;
          font-size: 100%;
          direction: rtl; }
    .superTreeviewLexicon .super-treeview > div > .super-treeview-node > .super-treeview-node-content > input[type=checkbox] {
          float: right; /* left; */
          margin: 6px 0 0 4px; /* 4px 5px 0 0 - indicates top, right, bottom, left*/
          line-height: normal; }
    .superTreeviewLexicon .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .super-treeview-triangle-btn {
          float: right; /* left; */
          margin-left: 4px;  /* 5px right; */
          cursor: pointer; }
    .superTreeviewLexicon .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .super-treeview-triangle-btn-right {
          width: 8px;
          height: 10px;
          margin-top: 5px !important;
          margin-right: 0; /* 2px left; */
          border-top: 5px solid transparent;
          border-right: 6px solid #000000;  /* left #000 */
          border-bottom: 5px solid transparent; }
    .superTreeviewLexicon .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .super-treeview-triangle-btn-down {
          width: 10px;
          height: 8px;
          margin-top: 8px; 
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 6px solid #000000; }  /* #000 */
          .superTreeviewLexicon .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .delete-btn {
          float: left; /* right; */
          cursor: pointer; }
    .superTreeviewLexicon .super-treeview > div > .super-treeview-node-transition {
      -webkit-transition-property: all;
      transition-property: all; }
    .superTreeviewLexicon .super-treeview > div > .super-treeview-node-transition-enter {
      opacity: 0; }
    .super-treeview > div > .super-treeview-node-transition-enter.super-treeview-node-transition-enter-active {
        opacity: 1; }
    .superTreeviewLexicon .super-treeview > div > .super-treeview-node-transition-exit {
      opacity: 1; }
    .superTreeviewLexicon .super-treeview > div > .super-treeview-node-transition-exit.super-treeview-node-transition-exit-active {
        opacity: 0;
        background-color: red; }
  
.lexiconToggleGroup button{
    /* display: block; */
    width: 100%;
    border: black solid 1.5px;
    background-color: lightgray;
    border-radius: 10px !important;
    color:black ;
}
.lexiconToggleGroup button:focus{
    outline: none;
}
.lexiconToggleGroup button:hover{
    background-color: rgb(192, 192, 192);
}
.lexiconToggleGroup{
    display: inline-block;
    max-width: 65px;
    min-width: 65px;
    margin: 45px auto;
    background:transparent !important;
    box-shadow: none !important;
}
/* .lexiconToggleGroup .MuiToggleButton-root-728.MuiToggleButton-selected-730 { */
.selectedToggleButton{
    color:black !important;
    font-weight: bold !important;
    background-color: darkgray !important;
}

.lexiconTreeBox{
    outline: black 2px solid;
    background-color:white;
    min-height: 100px !important;
    /* margin: 0px 40px; */
    min-width: 342px;
    max-width: 342px;
    display: block;
    text-align: right;
    /* overflow-y:hidden; */
  }
  .lexiconTreeLabel{
    margin:10px 0 0;
    display:inline-block;
  }
  .lexiconTreeLabel .labelLexicon{
    font-weight: bold;
    font-size: 18px;
  }
  .uncheckButton{
    font-size: 11 !important;
  }
  .lexiconSort{
    margin:4px 3px;
    /* max-width:100px; */
    padding:0 0 0 2px;
    max-height: 24px;
    width:100px;
    border-radius: 2px;
    font-size: 0.875rem !important;
    color: rgba(0, 0, 0, 0.54) !important;
    box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 3px 1px -2px rgb(0 0 0 / 12%);
    /* 0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 3px 1px -2px rgb(0 0 0 / 12%); */
    background-color: white;
    text-align: center;
  }
  .lexiconSort .MuiSelect-select-733 {
    padding: 4px 16px 4px 0 !important;
    font-size: 12px !important;
    text-overflow: clip !important;
  }
  .lexiconSort .MuiSelect-icon-738{
    top:calc(50% - 10px) !important
  }
  .lexiconSortOption{
    direction: rtl;
    background-color:white;
    text-align:center;
    font-size: 0.875rem !important;
    color: rgba(0, 0, 0, 0.54) !important;
    padding:5px 10px !important;
  }
  .lexiconSort .MuiSvgIcon-root-53{
    width: 17px !important;
    height: 17px !important;
  }
  .LocalizedComponent-iconButton-781{
    padding: 5px !important;
  }
  .resultsInstructions{
    font-size: 14px;
    font-style: italic;
  }

