
/* Legend */
#legend { 
    /* MOVED THIS TO YuchsinTree.css under .yuchsin-viewer-legend */
    /* bottom: 0; 
    position: fixed;
    margin: 15px;
    background-color: #222; */
}

#legend-button, #legend-content-dark, #legend-content-light, #surnames-button, .surnames-content, .surnames-heading {
    margin: 0px auto;
    padding-top: 5px;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 5px;
}

#legend-button, #surnames-button {
    color: #888;
}

#legend-button:hover, #surnames-button:hover {
    background-color: #2a2a2a;
    cursor: pointer;
}

#legend-content-dark {
    width: 270px;
    max-height: 455px;
    /* color: #ccc; */
    overflow-y: auto;
}

#legend-content-light {
    width: 270px;
    max-height: 455px;
    /* color: #ccc; */
    overflow-y: auto;
}

/*line spacing is 1px*/
#legend-content-dark p {
    margin: 1px 0px;
}

#legend-content-light p {
    margin: 1px 0px;
}


#legend-content-dark a {
  color: #80D0FF !important;
}

#legend-content-light a {
  color: #0050FF !important;
}

.legend-line {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.legend-line img {
    height: 100%;
    margin-right: 4px;
}

#controls #back-button, #controls #legend, #controls #surnames {
    z-index: 2;
}