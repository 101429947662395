.admin-controls-outer-container{
    margin-left: 225px;
}

.admin-controls-page-container{
    width: 1483px;
    max-width: calc(100% - 200px);
    margin: auto;
    padding-top: 20px;
}

.admin-controls-section-paper{
    padding: 20px;
}

.admin-controls-login-paper{
    padding: 20px;
    width: 600px;
    margin: 30px auto;
    text-align: center;
}

.admin-controls-page-title{
    font-weight: bold !important;
    margin: 20px 0 !important;
}

.admin-controls-login-message{
    font-weight: bold !important;
    color: #3260fb !important;
}

#admin-search-results-expiration-spinner .p-spinner-input{
    height: 24px !important;
    width: 55px !important;
}

.admin-controls-outer-question-container{
    display: flex;
}

.admin-controls-inner-question-container{
    width: calc(100% - 88px);
    margin: 6px 0;
}

.admin-controls-current-value{
    color: grey;
    font-style: italic;
}

#user-requests-grid .ag-header, #user-requests-grid .ag-header-row{
    height: 34px !important;
}

#user-requests-grid .ag-header-cell-label .ag-header-cell-text{
    white-space: normal !important;
    line-height: 1;
    overflow-wrap: normal;
}

.admin-drawer-paper{
    position: relative !important;
    height: calc(100vh - 100px) !important;
    background-color: #e7e7e7 !important;
    width: 225px !important;
    float: left;
}

.bold-list-item-text{
    font-weight: bold !important;
}

.number-column{
    text-align: right;
}

.request-log-option{
    display: inline !important;
    margin-right: 15px !important;
}

.admin-controls-section-title{
    margin: 25px 0 15px !important;
    font-weight: bold !important;
}

.results-cache-result-table-makor{
    background-color: #d3d3d3;
    font-weight: bold !important;
    padding: 0 11px;
    width: 140px;
    vertical-align: top;
}

.results-cache-results-cell{
    background-color: white;
    position: relative;
    line-height: 1.5;
    overflow: auto;
    white-space: normal;
    height: 100%;
    width: 100% !important;
    border: 2px solid grey;
    border-style: ridge;
    box-sizing: border-box;
    text-align: right;
    padding: 5px;
    font-family: SBL Hebrew;
    direction: rtl;
}

.results-cache-memory-data{
    position: absolute;
    z-index: 1;
    right: 0;
    top: -20px;
    height: 34px;
}

.clear-from-cache-button-cell{
    font-weight: 600 !important;
    padding: 0 !important;
    background-color: #d3d3d3;
    border-radius: 7px;
    cursor: pointer;
    font-size: 15px;
    /* width: 18px !important; */
    height: 18px !important;
    margin: 3px;
    /* transition: .25s; */
}

.clear-from-cache-button-cell:hover{
    background-color: #707070;
    color: white;
}

.admin-cache-option{
    margin-right: 10px !important;
}