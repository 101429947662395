/* MJE - moved style.css from C:\Work\TaryagAnalytics\projects-Git\code\BaseHaSefer-React\basehasefer\node_modules\react-super-treeview\dist to here */
/* MJE - modified from original (indicated where changed w/comments) in order to provide RTL support */

.super-treeview {
  font-size: 100%;}
  .super-treeview > div > .super-treeview-no-children-transition {
    transition-property: all; }
  .super-treeview > div > .super-treeview-no-children-transition-enter {
    opacity: 0;
    height: 0;
    overflow: hidden; }
    .super-treeview > div > .super-treeview-no-children-transition-enter.super-treeview-no-children-transition-enter-active {
      opacity: 1;
      height: 0; }
  .super-treeview > div > .super-treeview-no-children {
    overflow: hidden; }
    .super-treeview > div > .super-treeview-no-children > .super-treeview-no-children-content {
      margin: 2px 0; } /* 2px 0 */
  .super-treeview > div > .super-treeview-node {
    overflow: hidden; }
    .super-treeview > div > .super-treeview-node > .super-treeview-children-container {
      margin-right: 6px;  /* left 8px */
      padding-right: 15px;  /* left 20px */
      border-right: 1px solid #b87333; } /* left #000 */
      .super-treeview > div > .super-treeview-node > .super-treeview-children-container > .super-treeview-loading {
        padding: 0 0;  /* 0 3px */
        margin: 2px 0; } /* 2px 0 */
    .super-treeview > div > .super-treeview-node > .super-treeview-node-content {
      padding: 0 3px;  /* 0 3px */
      margin: 0 0; } /* 2px 0 */
      .super-treeview > div > .super-treeview-node > .super-treeview-node-content > label {
        vertical-align: top;
        text-overflow: ellipsis;
        width: calc(100% - 35px); /* 55px */
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
        margin: 0;
        font-weight: normal;
        font-size: 100%; }
      .super-treeview > div > .super-treeview-node > .super-treeview-node-content > input[type=checkbox] {
        float: right; /* left; */
        margin: 0 0 0 4px; /* 4px 5px 0 0 - indicates top, right, bottom, left*/
        line-height: normal; }
      .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .super-treeview-triangle-btn {
        float: right; /* left; */
        margin-left: 4px;  /* 5px right; */
        cursor: pointer; }
      .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .super-treeview-triangle-btn-right {
        width: 8px;
        height: 10px;
        margin-top: 0; /* 2px; */
        margin-right: 0; /* 2px left; */
        border-top: 5px solid transparent;
        border-right: 6px solid #b87333;  /* left #000 */
        border-bottom: 5px solid transparent; }
      .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .super-treeview-triangle-btn-down {
        width: 10px;
        height: 8px;
        margin-top: 5px; /* 5px; */
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 6px solid #b87333; }  /* #000 */
      .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .delete-btn {
        float: left; /* right; */
        cursor: pointer; }
  .super-treeview > div > .super-treeview-node-transition {
    transition-property: all; }
  .super-treeview > div > .super-treeview-node-transition-enter {
    opacity: 0; }
    .super-treeview > div > .super-treeview-node-transition-enter.super-treeview-node-transition-enter-active {
      opacity: 1; }
  .super-treeview > div > .super-treeview-node-transition-exit {
    opacity: 1; }
    .super-treeview > div > .super-treeview-node-transition-exit.super-treeview-node-transition-exit-active {
      opacity: 0;
      background-color: red; }
