   .superTreeViewCS .super-treeview * {
    box-sizing: border-box;
    -webkit-box-sizing: border-box; }
  
  .superTreeViewCS .super-treeview {
    font-size: 100%; }
    .superTreeViewCS .super-treeview > div > .super-treeview-no-children-transition {
      transition-property: all; }
    .superTreeViewCS .super-treeview > div > .super-treeview-no-children-transition-enter {
      opacity: 0;
      height: 0;
      overflow: hidden; }
      .superTreeViewCS .super-treeview > div > .super-treeview-no-children-transition-enter.super-treeview-no-children-transition-enter-active {
        opacity: 1;
        height: 0; }
    .superTreeViewCS .super-treeview > div > .super-treeview-no-children {
      overflow: hidden; }
      .superTreeViewCS .super-treeview > div > .super-treeview-no-children > .super-treeview-no-children-content {
        margin: 2px 0; }
    .superTreeViewCS .super-treeview > div > .super-treeview-node {
      overflow: hidden; }
      .superTreeViewCS .super-treeview > div > .super-treeview-node > .super-treeview-children-container {
        margin-left: 12px;
        padding-left: 15px;
        border-left: 1px solid #000; 
        margin-right: 0px;
        padding-right: 0px;
        border-right: none;}
        .superTreeViewCS .super-treeview > div > .super-treeview-node > .super-treeview-children-container > .super-treeview-loading {
          padding: 0 3px;
          margin: 2px 0; }
      .superTreeViewCS .super-treeview > div > .super-treeview-node > .super-treeview-node-content {
        white-space: nowrap;
        padding: 0 3px;
        margin: 2px 0; }
        .superTreeViewCS .super-treeview > div > .super-treeview-node > .super-treeview-node-content > label {
          vertical-align: top;
          text-overflow:inherit;
          width: calc(100% - 25px);
          overflow: auto;
          white-space: nowrap;
          display: inline-block;
          margin: 0;
          font-weight: normal;
          font-size: 100%; }
        .superTreeViewCS .super-treeview > div > .super-treeview-node > .super-treeview-node-content > input[type=checkbox] {
          float: left;
          margin: 4px 5px 0 0;
          line-height: normal; }
        .superTreeViewCS .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .super-treeview-triangle-btn {
          float: left;
          margin-right: 5px;
          cursor: pointer; }
        .superTreeViewCS .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .super-treeview-triangle-btn-none {
          cursor: none; }
        .superTreeViewCS .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .super-treeview-triangle-btn-right {
          width: 8px;
          height: 10px;
          margin-top: 2px;
          margin-left: 2px;
          border-top: 5px solid transparent;
          border-left: 6px solid #000;
          border-right: none;
          border-bottom: 5px solid transparent; }
        .superTreeViewCS .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .super-treeview-triangle-btn-down {
          width: 10px;
          height: 8px;
          margin-top: 5px;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 6px solid #000; }
        .superTreeViewCS .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .delete-btn {
          float: right;
          cursor: pointer; }
    .superTreeViewCS .super-treeview > div > .super-treeview-node-transition {
      transition-property: all; }
    .superTreeViewCS .super-treeview > div > .super-treeview-node-transition-enter {
      opacity: 0; }
      .superTreeViewCS .super-treeview > div > .super-treeview-node-transition-enter.super-treeview-node-transition-enter-active {
        opacity: 1; }
    .superTreeViewCS .super-treeview > div > .super-treeview-node-transition-exit {
      opacity: 1; }
      .superTreeViewCS .super-treeview > div > .super-treeview-node-transition-exit.super-treeview-node-transition-exit-active {
        opacity: 0;
        background-color: red; }
  

/* Hebrew is set to scroll indevidule lines */
.superTreeViewCSHeb .super-treeview * {
          box-sizing: border-box;
          -webkit-box-sizing: border-box; }
        
  .superTreeViewCSHeb .super-treeview {
          font-size: 100%; }
  .superTreeViewCSHeb .super-treeview > div > .super-treeview-no-children-transition {
            transition-property: all; }
  .superTreeViewCSHeb .super-treeview > div > .super-treeview-no-children-transition-enter {
            opacity: 0;
            height: 0;
            overflow: hidden; }
    .superTreeViewCSHeb .super-treeview > div > .super-treeview-no-children-transition-enter.super-treeview-no-children-transition-enter-active {
              opacity: 1;
              height: 0; }
  .superTreeViewCSHeb .super-treeview > div > .super-treeview-no-children {
            overflow: hidden; }
    .superTreeViewCSHeb .super-treeview > div > .super-treeview-no-children > .super-treeview-no-children-content {
              margin: 2px 0; }
  .superTreeViewCSHeb .super-treeview > div > .super-treeview-node {
            overflow: hidden; }
    .superTreeViewCSHeb .super-treeview > div > .super-treeview-node > .super-treeview-children-container {
              margin-right: 12px;
              padding-right: 15px;
              border-left: none; 
              border-right: 1px solid #000;}
              .superTreeViewCSHeb .super-treeview > div > .super-treeview-node > .super-treeview-children-container > .super-treeview-loading {
                padding: 0 3px;
                margin: 2px 0; }
            .superTreeViewCSHeb .super-treeview > div > .super-treeview-node > .super-treeview-node-content {
              white-space: nowrap;
              padding: 0 3px;
              margin: 2px 0; }
              .superTreeViewCSHeb .super-treeview > div > .super-treeview-node > .super-treeview-node-content > label {
                vertical-align: top;
                text-overflow:inherit;
                width: calc(100% - 25px);
                overflow: auto;
                white-space: nowrap;
                display: inline-block;
                margin: 0;
                font-weight: normal;
                font-size: 100%; }
              .superTreeViewCSHeb .super-treeview > div > .super-treeview-node > .super-treeview-node-content > input[type=checkbox] {
                float: right;
                margin: 4px 5px 0 0;
                line-height: normal; }
              .superTreeViewCSHeb .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .super-treeview-triangle-btn {
                float: right;
                margin-right: 5px;
                cursor: pointer; }
              .superTreeViewCSHeb .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .super-treeview-triangle-btn-none {
                cursor: none; }
              .superTreeViewCSHeb .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .super-treeview-triangle-btn-right {
                width: 8px;
                height: 10px;
                /* margin-top: 2px;
                margin-left: 2px; */
                margin-top: 0;
                margin-right: 2px;
                border-top: 5px solid transparent;
                border-left: none;
                border-right:6px solid #000 ;
                border-bottom: 5px solid transparent; }
              .superTreeViewCSHeb .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .super-treeview-triangle-btn-down {
                width: 10px;
                height: 8px;
                margin-top: 5px;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 6px solid #000; }
              .superTreeViewCSHeb .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .delete-btn {
                float: left;
                cursor: pointer; }
          .superTreeViewCSHeb .super-treeview > div > .super-treeview-node-transition {
            transition-property: all; }
          .superTreeViewCSHeb .super-treeview > div > .super-treeview-node-transition-enter {
            opacity: 0; }
            .superTreeViewCSHeb .super-treeview > div > .super-treeview-node-transition-enter.super-treeview-node-transition-enter-active {
              opacity: 1; }
          .superTreeViewCSHeb .super-treeview > div > .super-treeview-node-transition-exit {
            opacity: 1; }
            .superTreeViewCSHeb .super-treeview > div > .super-treeview-node-transition-exit.super-treeview-node-transition-exit-active {
              opacity: 0;
              background-color: red; }
.leftBar-search-criteria .super-treeview > div > .super-treeview-node > .super-treeview-node-content > label{
  overflow-x: clip !important;
  width: inherit;
}