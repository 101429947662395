.basic-3d{
  background-color: black;
}

span .yuchsin-tree-mini-source {
  display: inline-block;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: sub;
}

.yuchsin-tree-source-selector-button {
  margin: 4px !important;
  height: 18px !important;
  vertical-align: bottom;
  padding: 4px !important;
  min-width: 0 !important;
}

.yuchsin-name-dropdown {
  z-index: 10 !important;
}

/*
  for more info on the code below see https://github.com/dowjones/react-dropdown-tree-select/blob/develop/src/index.css
  and https://github.com/dowjones/react-dropdown-tree-select#faq
*/
.yuchsin-name-dropdown-container{
  z-index: 10 !important;
  display: inline-block;
  justify-content: center;
  vertical-align: top;
  width: 225px !important;
  font-size: 14px;
  cursor: pointer;
}

.yuchsin-name-dropdown-container .dropdown-trigger {
  z-index: 10 !important;
  display: flex !important;
  justify-content: center !important;
  width: 215px !important;
  padding: 0px 3px !important;
  background-color: white !important;
}

.yuchsin-name-dropdown-container .search {
  width: 175px !important;
}

.yuchsin-name-dropdown-container .dropdown-content{
  z-index: 10 !important;
  height: 300px !important;
  width: 200px !important;
  border-right: rgba(0, 0, 0, 0.25) 1px solid !important;
  border-left: rgba(0, 0, 0, 0.25) 1px solid !important;
  border-bottom: rgba(0, 0, 0, 0.25) 1px solid !important;
  overflow: auto;
}

.yuchsin-name-dropdown-container .node{
  height: 27px;
  text-align: right;
  overflow: hidden;
}

.yuchsin-name-dropdown-container .node:hover{
  background-color: lightgrey;
}

.yuchsin-name-dropdown-container .node>label {
  min-width: 100%;
}

.yuchsin-focusedNode {
  margin: 3px;
  width: 220px !important;
  padding: 0px 3px !important;
  font-size: 14px;
  text-align: right;
  text-overflow: ellipsis;
}

.yuchsin-pathText-container {
  padding: 10px 7px !important;
}

.yuchsin-pathText {
  height: 310px !important;
  width: 100% !important;
  padding: 3px 7px !important;
  overflow: scroll;
  font-size: 14px;
  text-align: right;
-webkit-user-select:text !important;
-moz-user-select:text !important;
-ms-user-select:text !important;
user-select:text !important;
}

.yuchsin-viewer-legend {
  z-index: 1;
  position: absolute;
  margin: 15px;
  /*  background-color: #222; */
  font-size: 14px;
}

.yuchsin-tree-hover-label-dark{
  width: 250px;
  background-color: #222222;
  padding: 10px;
}

.yuchsin-tree-hover-MAN-dark{
  color: #a0a0ff;
}

.yuchsin-tree-hover-WOMAN-dark{
  color: #ffa0a0;
}

.yuchsin-tree-hover-FAMILY-dark{
  color: #a0ffa0;
}

.yuchsin-tree-hover-label-light{
  width: 250px;
  background-color: #dddddd;
  color: black;
  padding: 10px;
}

.yuchsin-tree-hover-MAN-light{
  color: #2020c0;
}

.yuchsin-tree-hover-WOMAN-light{
  color: #c02020;
}

.yuchsin-tree-hover-FAMILY-light{
  color: #20c020;
}

.yuchsin-tree-pasuk-text-dark{
  color: #c8c8c8;
}

.yuchsin-tree-pasuk-text-light{
  color: #808080;
}

.yuchsin-tree-controls{
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 15px;
  /* background-color: #222 !important; */
  color: #888;
  font-size: 14px;
}

#settings-button {
    margin: 0px auto;
    padding-top: 5px;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 5px;
}

#settings-content p {
    margin: 5px 15px;
    /* color: #ccc; */
}

.yuchsin-tree-header{
  position: absolute;
    top: 35px;  /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */
    transform: translate(-50%, -50%);
    font-family: Shlomo Stam;
    font-size: 24px;
    background-color: transparent !important;
}

.yuchsin-tree-loading{
  position: absolute;
    top: 300px;  /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */
    transform: translate(-50%, -50%);
    color: #cccccc;
    font-size: 48px;
  background-color: transparent !important;
}

.yuchsin-tree-highlighting{
  position: absolute;
    top: 300px;  /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */
    transform: translate(-50%, -50%);
    /* color: #cccccc; */
    font-size: 48px;
    z-index: 1234;
  /* background-color: transparent !important; */
}

.yuchsin-tree-header-sm{
    font-family: TimesNewRoman;
    font-size: 20px;
}

.yuchsin-tree-controls label{
  margin: 0;
  /* color: #ccc; */
}

.yuchsin-tree-search {
  height: 24px;
}

.yuchsin-person2-selector{
  display: flex;
  justify-content: center;
  height: 30px;
  width: 380px;
}

.yuchsin-findPath-bottom {
  position: relative;
  direction: ltr !important;
 }

.yuchsin-findPath-bottom-caption {
  display: inline-block;
  width: 280px;
  text-align: center;
  align: center;
  color: blue;
  font-size: 18px;
  font-weight: bold;
  font-style: italic;
  padding: 0px 0px 7px 110px;
}

.yuchsin-findPath-bottom-button {
  display: inline-block;
  float: right;
  padding: 0px 7px 7px 7px;
}

.generations-selector-2D-popover [class*="MuiPopover-paper"]{
  padding: 5px !important;
  width: 415px;
}

.generations-slider {
  display: inline-block !important;
  width: 160px !important;
}

.generations-buttons {
  direction: ltr !important;
  padding: 0px 0px 0px 115px;
}