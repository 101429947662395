 .treeTaamParser {
  white-space: nowrap;
  min-width: 800px;
  min-height: 500px;
  height: 60vh;
  text-align: center !important;
  align-items: center !important;
  align-content: center !important;
  direction: ltr !important;

}

 .treeTaamParser ul {
  padding-top: 20px;
  padding-left: 0px;
  position: relative;
  transition: all 0s;
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  white-space: nowrap;
}

 .treeTaamParser li {
  display: inline-block;
  text-align: center;
  list-style-type: none;
  position: relative;
  vertical-align: top;
  padding: 20px 5px 0 5px;
  transition: all 0s;
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
}

/*We will use ::before and ::after to draw the connectors*/
 .treeTaamParser li::before,
 .treeTaamParser li::after {
  content: '';
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 1px solid #ccc;
  width: 50%;
  height: 20px;
}

 .treeTaamParser li::after {
  right: auto;
  left: 50%;
  border-left: 1px solid #ccc;
}

/*We need to remove left-right connectors from elements without any siblings*/
 .treeTaamParser li:only-child::after,
 .treeTaamParser li:only-child::before {
  display: none;
}

/*Remove space from the top of single children*/
 .treeTaamParser li:only-child {
  padding-top: 0;
}

/*Remove left connector from first child and right connector from last child*/
 .treeTaamParser li:first-child::before,
 .treeTaamParser li:last-child::after {
  border: 0 none;
}

/*Adding back the vertical connector to the last nodes*/
 .treeTaamParser li:last-child::before {
  border-right: 1px solid #ccc;
  border-radius: 0 5px 0 0;
  -webkit-border-radius: 0 5px 0 0;
  -moz-border-radius: 0 5px 0 0;
}

 .treeTaamParser li:first-child::after {
  border-radius: 5px 0 0 0;
  -webkit-border-radius: 5px 0 0 0;
  -moz-border-radius: 5px 0 0 0;
}

/*Time to add downward connectors from parents*/
 .treeTaamParser ul ul::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 1px solid #ccc;
  width: 0;
  height: 20px;
}

 .treeTaamParser li div {
  border: 1px solid #ccc;
  padding: 5px 10px;
  text-decoration: none;
  color: #666;
  font-family: arial, verdana, tahoma;
  font-size: 11px;
  display: inline-block;

  min-height: 30px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  transition: all 0s;
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
}

 .treeTaamParser li div .node {
  display: inline-block;
  color: black;
  padding: 10px;
  border-radius: 5px;
  font-size: 18px;
  direction: rtl;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}

 .קיסר{
  background-color: #C9BDFF;
}

 .מלך{
  background-color: #BECEF0;
}

 .משנה{
  background-color: #B3DEE2;
}

 .שליש{
  background-color: #9CFFC4;
}

/*
.משרת{
  background-color: #9CFFC4;
}
*/

 .מפסיק-נוסף{
  background-color: #efd0f5;
}

 .treeTaamParser li div .spacer {
  background-color: lightblue;
  display: inline-block;
  width: 10px;
}

 .treeTaamParser li div.leaf {
  cursor: default;
}

 .treeTaamParser li div.non-leaf {
  cursor: pointer;
  padding-right: 0px;
}

/*Time for some hover effects*/
/*We will apply the hover effect to the lineage of the element also*/
 .treeTaamParser li div.non-leaf:hover,
 .treeTaamParser li div.non-leaf:hover + ul li div {
  background: #c8e4f8;
  color: #000;
  border: 1px solid #94a0b4;
}

/*Connector styles on hover*/
 .treeTaamParser li div.non-leaf:hover + ul li::after,
 .treeTaamParser li div.non-leaf:hover + ul li::before,
 .treeTaamParser li div.non-leaf:hover + ul::before,
 .treeTaamParser li div.non-leaf:hover + ul ul::before {
  border-color: #94a0b4;
}

 .open-close-branch-icon{
    height: 17px !important;
    width: 17px !important;
    margin: 3px;
    vertical-align: center;
    color: #94a0b4;
}

 .taam-parser-title{
    display: inline;
    margin: 0 80px;
    /* 24%; */
}

/* .react-contexify__item__content.context-menu-checkbox{
  text-align: center;
} */