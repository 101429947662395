.exactnessSliderDiv .rc-slider {
    width: 100px;
    padding-top: 0px;
    margin-top: 30px;
    margin-right: 30px;
    margin-bottom: 30px;
    }

.parts-of-speech-dropdown-parent .rc-slider-mark-text{
    font-weight: bold !important;
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif" !important;
    font-size: 14px !important;
    transform: translateX(50%) !important;
    }
.simpleFuzzyPickerDiv .rc-slider-mark-text{
     font-family: "Roboto", "Helvetica", "Arial", "sans-serif" !important;
    font-size: 14px !important;
    transform: translateX(50%) !important;
}

.simpleFuzzyPickerDiv .exactnessSliderDiv .rc-slider-step{
    background-color: #b87333;
}
.simpleFuzzyPickerDiv .exactnessSliderDiv .rc-slider{
    margin-bottom: 30px;
}
.simpleFuzzyPickerDiv .exactnessSliderDiv .rc-slider-dot{
    border: 2px solid #b87333 !important;
}
.simpleFuzzyPickerDiv .exactnessSliderDiv .rc-slider-handle{
    border: 1px solid #b87333 !important;
}

.simpleFuzzyPickerDiv .exactnessSliderDiv .rc-slider-mark-text{
    color: #b87333 !important;
    font-size: 12px !important;
    letter-spacing: 0.02857em !important;
    }

.exactnessLevel-slider-wrapper-div {
    display: grid !important;
    grid-columns-template: 1fr 3fr;
}

.parts-of-speech-dropdown-parent .exactnessSliderDiv .rc-slider {
    width: 165px;
    margin-left: 75px;
    margin-top: 10px;
    margin-bottom: 30px;
    }
.rc-slider-disabled {
    background-color: transparent !important;
    }
.rc-slider-disabled .rc-slider-step{
    background-color: #e9e9e9 !important;
}

.simpleFuzzyPickerDiv .rc-slider-tooltip-inner{
    color: #b87333 !important;
    }

{/*formatting the positioning of the exactnesslevel slider and checkbox*/}
.exactnessLevel-slider-wrapper-div{
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 150px;
    margin-left: 35%;
    margin-top: 15px;
}

.advExactnessLevel-checkbox{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 5px;
}


