.superTreeviewLexicon .super-treeview {
    font-size: 100%;}
    .superTreeviewLexicon .super-treeview > div > .super-treeview-no-children-transition {
      transition-property: all; }
      .superTreeviewLexicon .super-treeview > div > .super-treeview-no-children-transition-enter {
      opacity: 0;
      height: 0;
      overflow: hidden; }
    .superTreeviewLexicon .super-treeview > div > .super-treeview-no-children-transition-enter.super-treeview-no-children-transition-enter-active {
        opacity: 1;
        height: 0; }
    .superTreeviewLexicon .super-treeview > div > .super-treeview-no-children {
      overflow: hidden; }
    .super-treeview > div > .super-treeview-no-children > .super-treeview-no-children-content {
        margin: 2px 0; 
        text-align: center;} /* 2px 0 */
    .superTreeviewLexicon .super-treeview > div > .super-treeview-node {
      overflow: hidden; }
    .superTreeviewLexicon .super-treeview > div > .super-treeview-node > .super-treeview-children-container {
        font-weight: normal !important;
        margin-right: 6px;  /* left 8px */
        padding-right: 15px;  /* left 20px */
        border-right: 1px solid #000000; } /* left #000 */
    .superTreeviewLexicon .super-treeview > div > .super-treeview-node > .super-treeview-children-container > .super-treeview-loading {
          font-weight: normal !important;
          padding: 0 0;  /* 0 3px */
          margin: 2px 0; } /* 2px 0 */
    .superTreeviewLexicon .super-treeview > div > .super-treeview-node > .super-treeview-node-content {
        padding: 0px 3px;  /* 0 3px */
        margin: 2px 0;
        line-height: normal;} /* 2px 0 */
    .superTreeviewLexicon .super-treeview > div > .super-treeview-node > .super-treeview-node-content:hover {
            background-color: lightblue;
    }
    .superTreeviewLexicon .super-treeview > div > .super-treeview-node > .super-treeview-node-content > label {
          vertical-align: top;
          text-overflow: ellipsis;
          width:inherit;/*set the width here so that the title doesn't show as [object:object] when hover next to the words*/
          /* width: calc(100% - 65px); 55px */
          overflow: hidden;
          white-space: nowrap;
          display: inline-block;
          margin: 0;
          font-weight: inherit;
          font-size: 100%;
          direction: rtl; }
    .superTreeviewLexicon .super-treeview > div > .super-treeview-node > .super-treeview-node-content > input[type=checkbox] {
          float: right; /* left; */
          margin: 6px 0 0 4px; /* 4px 5px 0 0 - indicates top, right, bottom, left*/
          line-height: normal; }
    .superTreeviewLexicon .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .super-treeview-triangle-btn {
          float: right; /* left; */
          margin-left: 4px;  /* 5px right; */
          cursor: pointer; }
    .superTreeviewLexicon .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .super-treeview-triangle-btn-right {
          width: 8px;
          height: 10px;
          margin-top: 5px !important;
          margin-right: 0; /* 2px left; */
          border-top: 5px solid transparent;
          border-right: 6px solid #000000;  /* left #000 */
          border-bottom: 5px solid transparent; }
    .superTreeviewLexicon .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .super-treeview-triangle-btn-down {
          width: 10px;
          height: 8px;
          margin-top: 8px; 
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 6px solid #000000; }  /* #000 */
          .superTreeviewLexicon .super-treeview > div > .super-treeview-node > .super-treeview-node-content > .delete-btn {
          float: left; /* right; */
          cursor: pointer; }
    .superTreeviewLexicon .super-treeview > div > .super-treeview-node-transition {
      transition-property: all; }
    .superTreeviewLexicon .super-treeview > div > .super-treeview-node-transition-enter {
      opacity: 0; }
    .super-treeview > div > .super-treeview-node-transition-enter.super-treeview-node-transition-enter-active {
        opacity: 1; }
    .superTreeviewLexicon .super-treeview > div > .super-treeview-node-transition-exit {
      opacity: 1; }
    .superTreeviewLexicon .super-treeview > div > .super-treeview-node-transition-exit.super-treeview-node-transition-exit-active {
        opacity: 0;
        background-color: red; }
  
.lexiconToggleGroup button{
    /* display: block; */
    width: 100%;
    border: black solid 1.5px;
    background-color: lightgray;
    border-radius: 10px !important;
    color:black ;
}
.lexiconToggleGroup button:focus{
    outline: none;
}
.lexiconToggleGroup button:hover{
    background-color: rgb(192, 192, 192);
}
.lexiconToggleGroup{
    display: inline-block;
    max-width: 65px;
    min-width: 65px;
    margin: 45px auto;
    background:transparent !important;
    box-shadow: none !important;
}
/* .lexiconToggleGroup .MuiToggleButton-root-728.MuiToggleButton-selected-730 { */
.selectedToggleButton{
    color:black !important;
    font-weight: bold !important;
    background-color: darkgray !important;
}

.lexiconTreeBox{
    outline: black 2px solid;
    background-color:white;
    min-height: 100px !important;
    /* margin: 0px 40px; */
    min-width: 342px;
    max-width: 342px;
    display: block;
    text-align: right;
    /* overflow-y:hidden; */
  }
  .lexiconTreeLabel{
    margin:10px 0 0;
    display:inline-block;
  }
  .lexiconTreeLabel .labelLexicon{
    font-weight: bold;
    font-size: 18px;
  }
  .uncheckButton{
    font-size: 11 !important;
  }
  .lexiconSort{
    margin:4px 3px;
    /* max-width:100px; */
    padding:0 0 0 2px;
    max-height: 24px;
    width:100px;
    border-radius: 2px;
    font-size: 0.875rem !important;
    color: rgba(0, 0, 0, 0.54) !important;
    box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 3px 1px -2px rgb(0 0 0 / 12%);
    /* 0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 3px 1px -2px rgb(0 0 0 / 12%); */
    background-color: white;
    text-align: center;
  }
  .lexiconSort .MuiSelect-select-733 {
    padding: 4px 16px 4px 0 !important;
    font-size: 12px !important;
    text-overflow: clip !important;
  }
  .lexiconSort .MuiSelect-icon-738{
    top:calc(50% - 10px) !important
  }
  .lexiconSortOption{
    direction: rtl;
    background-color:white;
    text-align:center;
    font-size: 0.875rem !important;
    color: rgba(0, 0, 0, 0.54) !important;
    padding:5px 10px !important;
  }
  .lexiconSort .MuiSvgIcon-root-53{
    width: 17px !important;
    height: 17px !important;
  }
  .LocalizedComponent-iconButton-781{
    padding: 5px !important;
  }
  .resultsInstructions{
    font-size: 14px;
    font-style: italic;
  }
