/*
input {
  width: 100%;
  height: 100px;
  padding: 20px;
  font-size: 20px;
  border: none;
  box-sizing: border-box;
}
*/

.simple-keyboard {
  max-width: 850px;
}

/*
  Theme: myTheme2
*/
.simple-keyboard.myTheme2 {
  padding: 0;
  border-radius: 5px;
  margin: 3px;
  width: calc(100% - 20px);
}

.simple-keyboard.myTheme2 .hg-row {
  margin: 0;
}

.simple-keyboard.myTheme2 .hg-row:not(:last-child) {
  margin-bottom: 0;
}

.simple-keyboard.myTheme2 .hg-row .hg-button {
  background: #4c7dd0; /* background: #9cbffb; */
  color: white;
  margin: 0;
  border-radius: 0;
  border: none;
  padding: 5px; /* 15px */
  box-sizing: border-box;
  height: 37px; /* 50px */
}

.simple-keyboard.myTheme2 .hg-row .hg-button:not(:last-child) {
  margin-right: 0;
}

.simple-keyboard.myTheme2 .hg-row .hg-button span {
  transition: all 0.05s ease-in-out;
}

/*

.simple-keyboard.myTheme2 .hg-row .hg-button:not(:active) {
  animation: myTheme2Anim 2s infinite alternate;
}
*/

.simple-keyboard.myTheme2 .hg-row .hg-button.myTheme2b {
  animation: none;
  background: rgba(255, 60, 60, 0.86);
  color: white;
}

.simple-keyboard.myTheme2 .hg-row .hg-button:active {
  color: white;
  background: rgba(0, 0, 0, 0.8);
}
/*
.simple-keyboard.myTheme2 .hg-row:nth-child(1) .hg-button {
  animation-delay: 0.5s;
}
.simple-keyboard.myTheme2 .hg-row:nth-child(2) .hg-button {
  animation-delay: 1s;
}
.simple-keyboard.myTheme2 .hg-row:nth-child(3) .hg-button {
  animation-delay: 1.5s;
}
.simple-keyboard.myTheme2 .hg-row:nth-child(4) .hg-button {
  animation-delay: 2s;
}
.simple-keyboard.myTheme2 .hg-row:nth-child(5) .hg-button {
  animation-delay: 2.5s;
}
*/

.regular-character-keyboard .simple-keyboard.myTheme2 .hg-button span {
  font-size: 18px;
  display: inline;
  text-align: center;
}

.regular-character-keyboard .simple-keyboard.myTheme2 .hg-button:hover span {
  font-size: 25px;
  display: inline;
  text-align: center;
}


.taam-nekuda-keyboard .simple-keyboard.myTheme2 .hg-button span {
  display: inline;
  text-align: center;
  font-size: 22px;
  line-height: 0px;
}

.taam-nekuda-keyboard .simple-keyboard.myTheme2 .hg-button:hover span {
  font-size: 30px;
  display: inline;
  text-align: center;
}

@keyframes myTheme2Anim {
  from {
    background: #9cbffb;
  }
  to {
    background: #4c7dd0;
  }
}

/* the following from https://codesandbox.io/s/github/hodgef/react-simple-keyboard-demos/tree/full-keyboard/
input {
  width: 100%;
  height: 100px;
  padding: 20px;
  font-size: 20px;
  border: none;
  box-sizing: border-box;
}
*/

/* the following seems to be based on .simple-keyboard.hg-theme-default .hg-button  */
.keyboardContainer {
  display: flex;
  background-color: rgba(0, 0, 0, 0.1);
  justify-content: center;
  width: 525px;
  margin: 0 auto;
  border-radius: 5px;
  direction: rtl;
}

.simple-keyboard.hg-theme-default {
  display: inline-block;
}

.simple-keyboard-letters.simple-keyboard {
/*
  width: 640px;
  min-width: 640px;
*/
  width: 240px;
  min-width: 240px;
  background: none;
}

.simple-keyboard-letters.simple-keyboard .hg-row:first-child {
  /* margin-bottom: 10px; */
}

.simple-keyboard-nekudos.simple-keyboard {
  width: 120px;
  min-width: 120px;
  background: none;
}

.simple-keyboard-taamim.simple-keyboard {
  width: 200px;
  min-width: 200px;
  background: none;
}

.simple-keyboard-sifreiEmet.simple-keyboard {
  width: 200px;
  min-width: 200px;
  background: none;
}

.simple-keyboard-special.simple-keyboard {
  width: 140px;
  min-width: 140px;
  background: none;
}


.simple-keyboard-arrows.simple-keyboard {
  align-self: flex-end;
  background: none;
}

.simple-keyboard .hg-button.selectedButton {
  background: rgba(5, 25, 70, 0.53);
  color: white;
}

.simple-keyboard .hg-button.emptySpace {
  pointer-events: none;
  background: none;
  border: none;
  box-shadow: none;
}

.simple-keyboard-arrows .hg-row {
  justify-content: center;
}

.simple-keyboard-arrows .hg-button {
  width: 50px;
  flex-grow: 0;
  justify-content: center;
  display: flex;
  align-items: center;
}

.controlArrows {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: column;
}

.simple-keyboard-control.simple-keyboard {
  background: none;
}

.simple-keyboard-control.simple-keyboard .hg-row:first-child {
  margin-bottom: 10px;
}

.simple-keyboard-control .hg-button {
  width: 50px;
  flex-grow: 0;
  justify-content: center;
  display: flex;
  align-items: center;
}

.numPad {
  display: flex;
  align-items: flex-end;
}

.simple-keyboard-numpad.simple-keyboard {
  background: none;
}

.simple-keyboard-numpad.simple-keyboard {
  width: 160px;
}

.simple-keyboard-numpad.simple-keyboard .hg-button {
  width: 50px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.simple-keyboard-numpadEnd.simple-keyboard {
  width: 50px;
  background: none;
  margin: 0;
  padding: 5px 5px 5px 0;
}

.simple-keyboard-numpadEnd.simple-keyboard .hg-button {
  align-items: center;
  justify-content: center;
  display: flex;
}

.simple-keyboard-numpadEnd .hg-button.hg-standardBtn.hg-button-plus {
  height: 85px;
}

.simple-keyboard-numpadEnd.simple-keyboard .hg-button.hg-button-enter {
  height: 85px;
}

.simple-keyboard.hg-theme-default .hg-button.hg-selectedButton {
  background: rgba(5, 25, 70, 0.53);
  color: white;
}

.hg-button.hg-functionBtn.hg-button-space {
  width: 350px;
}

