.about-rnd{
  background-color: black !important;

}

.about-dialog-content-root{
  flex: 1 1 auto;
  padding: 0 24px 24px;
  overflow-y: hidden !important;
  max-height: calc(100% - 68px) !important;
  cursor: default;
}

.about-dialog-paper{
    color: dodgerblue !important;
    background-color: black !important;
}

.about-dialog-title *{
    color: dodgerblue !important;
}

.about-dialog-expand-colapse-parent{
  position: absolute;
  top: -2px;
  white-space: nowrap;
}

.about-dialog-title-text-parent{
  max-width: calc(100% - 260px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}


.about-dialog-expansion-panel{
    background-color: black !important;
    border: 1px solid dodgerblue;
}

.about-dialog-expansion-panel *{
    color: #62adf5 !important;
}

.about-dialog-expansion-panel-summary *{
    color: dodgerblue !important;
}

.about-dialog-resize-button {
  padding: 0 !important;
  min-width: 25px !important;
  vertical-align: -6px !important;
  border-radius: 0px !important;
  color: dodgerblue !important;
  min-width: unset !important;
  width: 24px !important;
  background: #2e2e2e !important;
}


.about-dialog-resize-button:hover {
  background: #474747 !important;
}

.about-rnd .react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  border: 1px solid dodgerblue;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
}

.about-rnd .react-tabs__tab--selected {
  background: #fff;
  color: black;
  border-color: dodgerblue;
  border-radius: 5px 5px 0 0;
  background: #62adf5;
  font-weight: bold;
}

.about-rnd .react-tabs__tab-list {
  border-bottom: 1px solid dodgerblue;
  margin: 0 0 10px;
  padding: 0;
  color: dodgerblue;
}

.about-rnd .react-tabs__tab:focus:after {
  height: 0px;
}

.about-rnd h3{
  color: dodgerblue;
}

.about-dialog-tabs-container{
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
}

.about-dialog-tab-panels-parent{
  flex-grow: 1;
  overflow: auto;
}

/* unvisited link */
a:link {
  color: #80D0FF !important;
}

/* visited link */
a:visited {
  color: #80D0FF !important;
}

/* mouse over link */
a:hover {
  color: #80D0FF !important;
}

/* selected link */
a:active {
  color: #80D0FF !important;
}