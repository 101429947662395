/* props marked important to override rc-slider styles in its index.css*/
.rc-slider-vertical .rc-slider-rail {
  height: 140% !important;
  margin-left: -1px !important;
  margin-top: -3px !important;
  width: 6px !important;
  background-color: #61baff !important;
}

.rc-slider-handle {
  position: absolute;
  margin-left: -5px !important;
  width: 14px !important;
  height: 14px !important;
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: grab;
  border-radius: 50%;
  border: solid 2px #61baff !important;
}
/*
.rc-slider-handle {
  position: absolute;
  margin-left: -5px !important;
  width: 14px !important;
  height: 10px !important;
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: grab;
  border-radius: 50%;
  border: solid 2px #61baff !important;
}

.rc-slider-dot {
  bottom: -9999px !important;
  margin-left: 0px !important;
  width: 0px !important;
  height: 0px !important;
}
*/

.rc-slider-mark-text {
  text-align: left !important;
  text-width: 20px !important;
  color: gray !important;
  font-size: 13px !important;
}
.rc-slider-mark-text-active {
  text-width: 20px !important;
  text-align: left !important;
  font-weight: bold !important;
  color: black !important;
  font-size: 16px !important;
}

/*make sure toggle buttons are labeled  in lowercase*/
.MuiToggleButton-root-167{
text-transform: capitalize !important;
}