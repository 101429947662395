span.a:link { color: #0000FF !important; }

.help-rnd{
  background-color: white !important;
  color: black;
}

.help-dialog-content-root{
  flex: 1 1 auto;
  padding: 0 24px 24px;
  overflow-y: hidden !important;
  max-height: calc(100% - 88px) !important;
  cursor: default;
}

.help-dialog-paper{
    color: black !important;
    background-color: white !important;
}

.help-dialog-title *{
    color: black !important;
}

.help-dialog-expand-colapse-parent{
  position: absolute;
  top: -2px;
  white-space: nowrap;
}

.help-dialog-title-text-parent{
  max-width: calc(100% - 260px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}


.help-dialog-expansion-panel{
    background-color: white !important;
    border: 1px solid black;
}

.help-dialog-expansion-panel *{
    color: black !important;
}

.help-dialog-expansion-panel-summary *{
    color: black !important;
}

.help-dialog-close-button{
  background: dodgerblue !important;
  min-width: unset !important;
  width: 24px;
  padding: 0 !important;
  position: absolute !important;
  color: white !important;
}


.help-dialog-resize-button {
  padding: 0 !important;
  min-width: 25px !important;
  vertical-align: -6px !important;
  border-radius: 0px !important;
  color: dodgerblue !important;
  min-width: unset !important;
  width: 24px !important;
  background: #efefef !important;
}

.help-dialog-resize-button:hover {
  background: #c4c4c4 !important;
}

.help-rnd .react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  border: 1px solid dodgerblue;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
}

.help-rnd .react-tabs__tab--selected {
  background: #fff;
  color: white;
  border-color: dodgerblue;
  border-radius: 5px 5px 0 0;
  background: dodgerblue;
  font-weight: bold;
}

.help-rnd .react-tabs__tab-list {
  border-bottom: 1px solid dodgerblue;
  margin: 0 0 10px;
  padding: 0;
  color: dodgerblue;
}

.help-rnd .react-tabs__tab:focus:after {
  height: 0px;
}

.help-rnd h3{
  color: black;
}

/* react-dropdown-tree-select overrides - main definitions are in it's own styles.css w/i node_modules*/
.node.disabled>*{
  font-weight: bold !important;
  cursor:default !important;
}




/* fallback, until https://github.com/CompuIves/codesandbox-client/issues/174 is resolved */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v36/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
    format("woff2");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

/* mdl-demo is a custom classname to increases the specificity of our styles. It can be anything. 
 * The idea is that it is easy to extend/override builtin styles with very little effort.
 */

.mdl-demo {
  position: absolute;
  top: 0;
  cursor: initial;
}

.mdl-demo .checkbox-item{
  display: none;
}

.mdl-demo.en {
  left: 68px;
}

.mdl-demo.he {
  right: 68px;
}

.mdl-demo .node label:hover{
  background: lightgray;
  transition: .25s;
}

.mdl-demo .tag-item:not(:last-child){
  display: none;
}

.mdl-demo .dropdown-trigger > span:after {
  font-size: 12px;
  color: #555;
}

.mdl-demo .toggle {
  font: normal normal normal 18px/1 "Material Icons";
  color: #555;
  white-space: pre;
  margin-right: 4px;
}

.mdl-demo .toggle.collapsed::after {
  cursor: pointer;
  content: "\E5CF";
  vertical-align: middle;
}

.mdl-demo .toggle.expanded::after {
  cursor: pointer;
  content: "\E5CE";
  vertical-align: middle;
}

/* checkbox styles */
.mdl-demo .checkbox-item {
  position: relative;
  width: 1rem;
  height: 1rem;
  margin-right: 0.75rem;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  vertical-align: middle;
}

.mdl-demo .checkbox-item:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  border: 2px solid #aaa;
  transition: all 0.3s ease-in-out;
}

.mdl-demo .checkbox-item:checked:before {
  height: 50%;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border-top-style: none;
  border-right-style: none;
  border-color: #2196f3;
}
.help-dialog-tabs-container{
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
}

.help-dialog-tab-panels-parent{
  flex-grow: 1;
  overflow: auto;
}




 /* Font Definitions from User Manual MS-Word conversion */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:"Franklin Gothic Heavy";
	panose-1:2 11 9 3 2 1 2 2 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin:0in;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoHeader, li.MsoHeader, div.MsoHeader
	{mso-style-link:"Header Char";
	margin:0in;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoFooter, li.MsoFooter, div.MsoFooter
	{mso-style-link:"Footer Char";
	margin:0in;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
/* this one throws other stuff off so don't use
p
	{margin-right:0in;
	margin-left:0in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
*/
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
span.HeaderChar
	{mso-style-name:"Header Char";
	mso-style-link:Header;}
span.FooterChar
	{mso-style-name:"Footer Char";
	mso-style-link:Footer;}
 /* Page Definitions */
 @page WordSection1
	{size:8.5in 11.0in;
	margin:1.0in 1.0in 1.0in 1.0in;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0in;}
ul
	{margin-bottom:0in;}